import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import { connect } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import Logo from "../../images/logo.png";
import LogoA from "../../images/logoA.png";
import * as actions from "../../redux/actions";

import ShortShoutLink from "./ShortShoutLink";

class ShortShout extends React.Component {
  _onLinkClick = (link) => {
    console.log(link);
    this.props.updateLinkCountRequest(link);
  };

  _renderAvatar = () => {
    let { avatar } = this.props;
    if (avatar && avatar !== "") {
      return <img src={avatar} />;
    } else {
      return <UserOutlined />;
    }
  };

  render() {
    let allLinks = this.props.links || [];

    return (
      <div
        style={{
          padding: "10px",
          borderRadius: "20px",
          paddingTop: "20px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          <Avatar
            size={80}
            style={{ backgroundColor: "#1890ff" }}
            icon={this._renderAvatar()}
          />
          <br />
          <h3>@{this.props.username}</h3>
        </div>
        <br />

        {allLinks.map((link, i) => {
          return (
            <ShortShoutLink key={i} link={link} onClick={this._onLinkClick} />
          );
        })}
        <br />
        <Link to="/" target="_blank">
          <div style={{ textAlign: "center" }}>
            <img
              alt="shortshout.in logo"
              src={LogoA}
              style={{ height: "14px", marginTop: "20px" }}
            />
          </div>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateLinkCountRequest: (data) =>
    dispatch(actions.updateLinkCountRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShortShout);
