import React from "react";
import { Card } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
  WhatsAppOutlined,
  GithubOutlined,
} from "@ant-design/icons";

const { Meta } = Card;

class ShortShoutLink extends React.Component {
  _openLink = () => {
    this.props.onClick(this.props.link);

    let url = this.props.link.url;
    console.log(url);
    let prefix = "http://";
    let prefix2 = "https://";
    if (
      url.substr(0, prefix.length) !== prefix &&
      url.substr(0, prefix2.length) !== prefix2
    ) {
      url = prefix + url;
    }
    console.log(url);
    let win = window.open(url, "_blank");
    win.focus();
  };

  _getLinkIcon = (link) => {
    const { linkType } = link;
    if (linkType === "facebook") {
      return (
        <span className="shortshout-linktype-icon">
          <FacebookOutlined />
        </span>
      );
    } else if (linkType === "instagram") {
      return (
        <span className="shortshout-linktype-icon">
          <InstagramOutlined />
        </span>
      );
    } else if (linkType === "twitter") {
      return (
        <span className="shortshout-linktype-icon">
          <TwitterOutlined />
        </span>
      );
    } else if (linkType === "linkedin") {
      return (
        <span className="shortshout-linktype-icon">
          <LinkedinOutlined />
        </span>
      );
    } else if (linkType === "youtube") {
      return (
        <span className="shortshout-linktype-icon">
          <YoutubeOutlined />
        </span>
      );
    } else if (linkType === "whatsapp") {
      return (
        <span className="shortshout-linktype-icon">
          <WhatsAppOutlined />
        </span>
      );
    } else if (linkType === "github") {
      return (
        <span className="shortshout-linktype-icon">
          <GithubOutlined />
        </span>
      );
    }
    return null;
  };

  render() {
    return (
      <Card className="shortshout-link-buttton" onClick={this._openLink}>
        <Meta
          title={
            <div
              style={{
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {this._getLinkIcon(this.props.link)}
              {this.props.link.title}
            </div>
          }
        />
      </Card>
    );
  }
}

export default ShortShoutLink;
