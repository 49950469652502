import constants from "./constants";
import { takeLatest, takeEvery, all } from "redux-saga/effects";
import {
  registerRequest,
  loginRequest,
  isLoggedInUser,
  logoutRequest,
  resetPasswordRequest,
  verifyEmailRequest,
  sendEmailVerifyCodeRequest,
  addUsernameRequest,
  getRefreshTokenRequest,
  updatePasswordRequest,
  uploadAvatarRequest,
  myAvatarRequest,
} from "./auth/action";
import {
  getPublicShortShoutRequest,
  getRecentlyAddedUsersRequest,
} from "./shortshout/action";

import {
  newLinkRequest,
  myLinksRequest,
  deleteLinkRequest,
  updateLinkCountRequest,
  updateLinkStatusRequest,
  updateLinkRequest,
} from "./links/action";

import {
  adminGetLatestUsersRequest,
  adminGetLatestLinksRequest,
  adminGetCompleteStatsRequest,
  adminSendDoVerificationEmailRequest,
} from "./admin/action";

import { myStatsRequest } from "./dashboard/action";

import {
  saveInstagramAccountsRequest,
  myInfluencerPagesRequest,
  publicInfluencerPagesRequest,
  updateInfluencerInstagramRequest,
  updateInfluencerTwitterRequest,
  twitterAuthRequest,
  twitterAuthVerifyRequest,
  newCampaignRequest,
  myCampaignsRequest,
  myCampaignRequest,
  updateMyCampaignRequest,
  activeCampaignsRequest,
  applyCampaignRequest,
  saveYoutubeAccountsRequest,
  updateInfluencerYoutubeRequest,
} from "./influencers/action";

import {
  sendEmailVerifyCodeError,
  updateInfluencerTwitterError,
} from "./actions";

function* watchActions() {
  /*register*/
  yield takeLatest(constants.REGISTER_REQUEST, registerRequest);

  /*login*/
  yield takeLatest(constants.LOGIN_REQUEST, loginRequest);

  /*reset password*/
  yield takeLatest(constants.RESET_PASSWORD_REQUEST, resetPasswordRequest);

  /*verify email */
  yield takeLatest(constants.VERIFY_EMAIL_REQUEST, verifyEmailRequest);

  /*add username*/
  yield takeLatest(constants.ADD_USERNAME_REQUEST, addUsernameRequest);

  /*get refresh token*/
  yield takeLatest(constants.GET_REFRESH_TOKEN_REQUEST, getRefreshTokenRequest);

  /*update user password*/
  yield takeLatest(constants.UPDATE_PASSWORD_REQUEST, updatePasswordRequest);

  /*upload avatar*/
  yield takeLatest(constants.UPLOAD_AVATAR_REQUEST, uploadAvatarRequest);

  /*my avatar*/
  yield takeLatest(constants.MY_AVATAR_REQUEST, myAvatarRequest);

  /*send email verification code*/
  yield takeLatest(
    constants.SEND_EMAIL_VERIFY_CODE_REQUEST,
    sendEmailVerifyCodeRequest
  );

  yield takeLatest(constants.IS_LOGGEDIN_USER, isLoggedInUser);

  /*log out*/
  yield takeLatest(constants.LOGOUT_REQUEST, logoutRequest);

  /*new link*/
  yield takeLatest(constants.NEW_LINK_REQUEST, newLinkRequest);

  /*delete link*/
  yield takeLatest(constants.DELETE_LINK_REQUEST, deleteLinkRequest);

  /*my Links*/
  yield takeLatest(constants.MY_LINKS_REQUEST, myLinksRequest);

  /* get Public Short Shout */
  yield takeLatest(
    constants.GET_PUBLIC_SHORT_SHOUT_REQUEST,
    getPublicShortShoutRequest
  );

  /*update link count*/
  yield takeLatest(
    constants.UPDATE_LINK_CLICK_COUNT_REQUEST,
    updateLinkCountRequest
  );

  /*update link status*/
  yield takeLatest(
    constants.UPDATE_LINK_STATUS_REQUEST,
    updateLinkStatusRequest
  );

  /*get recenlty added users*/
  yield takeLatest(
    constants.GET_RECENTLY_ADDED_USERS_REQUEST,
    getRecentlyAddedUsersRequest
  );

  /*my stats*/
  yield takeLatest(constants.MY_STATS_REQUEST, myStatsRequest);

  /*update link*/
  yield takeLatest(constants.UPDATE_LINK_REQUEST, updateLinkRequest);

  /*admin get latest users*/
  yield takeLatest(
    constants.ADMIN_GET_LATEST_USERS_REQUEST,
    adminGetLatestUsersRequest
  );

  /*admin get latest links*/
  yield takeLatest(
    constants.ADMIN_GET_LATEST_LINKS_REQUEST,
    adminGetLatestLinksRequest
  );

  /*admin get complete stats*/
  yield takeLatest(
    constants.ADMIN_GET_COMPLETE_STATS_REQUEST,
    adminGetCompleteStatsRequest
  );

  /*admin do send verification email*/
  yield takeLatest(
    constants.ADMIN_SEND_DO_VERIFICATION_EMAIL_REQUEST,
    adminSendDoVerificationEmailRequest
  );

  /*save instagram accounts*/
  yield takeLatest(
    constants.SAVE_INSTAGRAM_ACCOUNTS_REQUEST,
    saveInstagramAccountsRequest
  );
  /*my infulencers pages*/
  yield takeLatest(
    constants.MY_INFLUENCER_PAGES_REQUEST,
    myInfluencerPagesRequest
  );
  /*public infulencers pages*/
  yield takeLatest(
    constants.PUBLIC_INFLUENCER_PAGES_REQUEST,
    publicInfluencerPagesRequest
  );

  /*update influencer instagram*/
  yield takeLatest(
    constants.UPDATE_INFLUENCER_INSTAGRAM_REQUEST,
    updateInfluencerInstagramRequest
  );

  /*TWITTER AUTH*/
  yield takeLatest(constants.TWITTER_AUTH_REQUEST, twitterAuthRequest);

  /*TWITTER AUTH verify*/
  yield takeLatest(
    constants.TWITTER_AUTH_VERIFY_REQUEST,
    twitterAuthVerifyRequest
  );

  /*update influencer twitter*/
  yield takeLatest(
    constants.UPDATE_INFLUENCER_TWITTER_REQUEST,
    updateInfluencerTwitterRequest
  );

  /*new campaign*/
  yield takeLatest(constants.NEW_CAMPAIGN_REQUEST, newCampaignRequest);

  /*my campaigns*/
  yield takeLatest(constants.MY_CAMPAIGNS_REQUEST, myCampaignsRequest);

  /*my campaign*/
  yield takeLatest(constants.MY_CAMPAIGN_REQUEST, myCampaignRequest);

  /*update my campaign*/
  yield takeLatest(
    constants.UPDATE_MY_CAMPAIGN_REQUEST,
    updateMyCampaignRequest
  );

  /*active campaigns*/
  yield takeLatest(constants.ACTIVE_CAMPAIGNS_REQUEST, activeCampaignsRequest);

  /*apply campaign*/
  yield takeLatest(constants.APPLY_CAMPAIGN_REQUEST, applyCampaignRequest);

  /*save youtube accounts*/
  yield takeLatest(
    constants.SAVE_YOUTUBE_ACCOUNTS_REQUEST,
    saveYoutubeAccountsRequest
  );

  /*update influencer youtube*/
  yield takeLatest(
    constants.UPDATE_INFLUENCER_YOUTUBE_REQUEST,
    updateInfluencerYoutubeRequest
  );
}

export default function* rootSaga() {
  yield all([watchActions()]);
}
