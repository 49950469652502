import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  progressBar: {
    data: false,
  },
};

/* progress bar */
const showProgressBar = (state, action) =>
  update(state, {
    progressBar: {
      data: { $set: action.payload },
    },
  });

export default handleActions(
  {
    [constants.SHOW_PROGRESS_BAR]: showProgressBar,
  },
  initialState
);
