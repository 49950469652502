import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Menu } from "antd";

function MenuAdmin() {
  return (
    <div id="menu-influencers">
      <Menu
        // onClick={handleClick}
        // selectedKeys={[current]}
        mode="horizontal"
      >
        <Menu.Item key="dashboard">
          <Link to="/masteradmin">Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="masteradmin/users">
          <Link to="/masteradmin/users">Users</Link>
        </Menu.Item>
        <Menu.Item key="masteradmin/links">
          <Link to="/masteradmin/links">Links</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default MenuAdmin;
