import React from "react";

import { Button, Row, Col, Space } from "antd";
import { TwitterOutlined } from "@ant-design/icons";

function PublicListInfluencersTwitter(props) {
  const { twitterPages, loggedUserData } = props;

  const openInstagramPage = (username) => {
    let link = "https://twitter.com/" + username;
    window.open(link);
  };

  const openLinkNewPage = (link) => {
    window.open(link);
  };

  const renderPageDetails = (page) => {
    const {
      biography,
      followers_count,
      follows_count,
      media_count,
      name,
      website,
      profile_picture_url,
      username,
      costPerPost,
    } = page;

    let bioWebsite = "";
    if (website !== "") {
      bioWebsite = (
        <Button
          className="bio-page-link"
          type="link"
          onClick={() => openLinkNewPage(website)}
        >
          {website}
        </Button>
      );
    }

    let costPerPostFinal = "Sign In For Details";
    if (loggedUserData) {
      costPerPostFinal = "-NA-";
      if (costPerPost && costPerPost > 0) {
        costPerPostFinal = "₹" + costPerPost;
      }
    }

    return (
      <Row id="instagram-page-view-wrapper">
        <Col xs={24} md={3} className="details-wrap">
          <img alt="logo" className="profile-image" src={profile_picture_url} />
          <div className="user-name">
            <TwitterOutlined className="instagram-icon" />
            {username}
          </div>
        </Col>
        <Col xs={24} md={5} className="details-bio-wrap">
          <div className="user-fullname">{name}</div>
          <div className="user-bio">{biography}</div>
          <div className="user-website">{bioWebsite}</div>
        </Col>
        <Col xs={12} md={3} className="posts-wrap">
          <div className="span-label">Posts</div>
          <div className="span-count">{media_count}</div>
        </Col>
        <Col xs={12} md={3} className="followers-wrap">
          <div className="span-label">Followers</div>
          <div className="span-count">{followers_count}</div>
        </Col>
        <Col xs={12} md={3} className="following-wrap">
          <div className="span-label">Following</div>
          <div className="span-count">{follows_count}</div>
        </Col>
        <Col xs={12} md={3} className="cost-wrap">
          <div className="span-label">Cost Per Post</div>
          <div className="span-signin">{costPerPostFinal}</div>
        </Col>
        <Col xs={24} md={4} className="contact-wrap">
          <Button
            className="instagram-page-link"
            type="link"
            onClick={() => openInstagramPage(username)}
          >
            Contact {username}
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <div className="public-instagram-pages">
      {twitterPages.map((page) => {
        return renderPageDetails(page);
      })}
    </div>
  );
}

export default PublicListInfluencersTwitter;
