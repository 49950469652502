import React from "react";
import { Row, Col, Avatar } from "antd";
import {
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

import {
  getUserInstagramLink,
  getUserTwitterLink,
  getUserYoutubeLink,
} from "../../services";

function CampaignApplicants(props) {
  const { campaignApplications } = props;
  const renderUserInfo = (userInfo) => {
    const { name } = userInfo;
    return (
      <Row className="user-details">
        <Col xs={24} md={24}>
          {name}
        </Col>
      </Row>
    );
  };
  const renderTwitterPages = (pages) => {
    return pages.map((page, key) => {
      const {
        username,
        name,
        followers_count,
        follows_count,
        media_count,
        costPerPost,
        profile_picture_url,
      } = page;
      let twitterPageLink = getUserTwitterLink(username);
      return (
        <Row key={key} className="twitter-page">
          <Col xs={12} md={2} className="details-wrap">
            <div className="label">Twitter</div>
            <div className="value">
              <TwitterOutlined />
            </div>
          </Col>
          <Col xs={12} md={3} className="details-wrap">
            <div className="label">{username}</div>
            <div className="value">
              <Avatar src={profile_picture_url} />
            </div>
          </Col>
          <Col xs={12} md={3} className="details-wrap">
            <div className="label">Tweets</div>
            <div className="value">{media_count}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Followers</div>
            <div className="value">{followers_count}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Following</div>
            <div className="value">{follows_count}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Cost Per Post</div>
            <div className="value">{costPerPost}</div>
          </Col>
          <Col xs={24} md={4} className="details-wrap">
            <div className="label">Action</div>
            <div className="value">
              <a href={twitterPageLink} target="_BLANK">
                Contact
              </a>
            </div>
          </Col>
        </Row>
      );
    });
  };

  const renderInstagramPages = (pages) => {
    return pages.map((page, key) => {
      const {
        username,
        name,
        followers_count,
        follows_count,
        media_count,
        costPerPost,
        profile_picture_url,
      } = page;
      let instagramPageLink = getUserInstagramLink(username);
      return (
        <Row key={key} className="instagram-page">
          <Col xs={12} md={2} className="details-wrap">
            <div className="label">Instagram</div>
            <div className="value">
              <InstagramOutlined />
            </div>
          </Col>
          <Col xs={12} md={3} className="details-wrap">
            <div className="label">{username}</div>
            <div className="value">
              <Avatar src={profile_picture_url} />
            </div>
          </Col>
          <Col xs={12} md={3} className="details-wrap">
            <div className="label">Posts</div>
            <div className="value">{media_count}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Followers</div>
            <div className="value">{followers_count}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Following</div>
            <div className="value">{follows_count}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Cost Per Post</div>
            <div className="value">{costPerPost}</div>
          </Col>
          <Col xs={24} md={4} className="details-wrap">
            <div className="label">Action</div>
            <div className="value">
              <a href={instagramPageLink} target="_BLANK">
                Contact
              </a>
            </div>
          </Col>
        </Row>
      );
    });
  };

  const renderYoutubePages = (pages) => {
    return pages.map((page, key) => {
      const {
        id,
        username,
        name,
        followers_count,
        follows_count,
        media_count,
        costPerPost,
        profile_picture_url,
        videos_view_count,
      } = page;
      let instagramPageLink = getUserYoutubeLink(id);
      return (
        <Row key={key} className="instagram-page">
          <Col xs={12} md={2} className="details-wrap">
            <div className="label">Youtube</div>
            <div className="value">
              <YoutubeOutlined />
            </div>
          </Col>
          <Col xs={12} md={3} className="details-wrap">
            <div className="label">{username}</div>
            <div className="value">
              <Avatar src={profile_picture_url} />
            </div>
          </Col>
          <Col xs={12} md={3} className="details-wrap">
            <div className="label">Videos</div>
            <div className="value">{media_count}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Subscribers</div>
            <div className="value">{followers_count}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">View Count</div>
            <div className="value">{videos_view_count}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Cost Per Post</div>
            <div className="value">{costPerPost}</div>
          </Col>
          <Col xs={24} md={4} className="details-wrap">
            <div className="label">Action</div>
            <div className="value">
              <a href={instagramPageLink} target="_BLANK">
                Contact
              </a>
            </div>
          </Col>
        </Row>
      );
    });
  };

  return (
    <div id="campaign-applicants">
      <br />
      <h3>Influencers Applied</h3>
      {campaignApplications && campaignApplications.length > 0
        ? campaignApplications.map((applicant, key) => {
            const {
              userInfo,
              userInstagramPages,
              userTwitterPages,
              userYoutubePages,
            } = applicant;
            return (
              <div key={key} className="applicant">
                {renderUserInfo(userInfo)}
                <br />
                {renderTwitterPages(userTwitterPages)}
                <br />
                {renderInstagramPages(userInstagramPages)}
                <br />
                {renderYoutubePages(userYoutubePages)}
              </div>
            );
          })
        : null}

      {/* <Col xs={12} md={4} className="details-wrap">
        <div className="label">Status</div>
        <div className="value">{status}</div>
      </Col>
      <Col xs={12} md={4} className="details-wrap">
        <div className="label">Platform</div>
        <div className="value">{platform}</div>
      </Col>
      <Col xs={12} md={6} className="details-wrap">
        <div className="label">Campaign Name</div>
        <div className="value">{name}</div>
      </Col>
      <Col xs={12} md={4} className="details-wrap">
        <div className="label">Budget</div>
        <div className="value">{budgetToShow}</div>
      </Col>
      <Col xs={24} md={6} className="details-wrap">
        <div className="label">Description</div>
        <div className="value">{description}</div>
      </Col> */}
      {/* </Row> */}
    </div>
  );
}

export default CampaignApplicants;
