import React from "react";
import { connect } from "react-redux";
import { Menu, Layout } from "antd";
import { withRouter } from "react-router-dom";
import {
  AppstoreOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  ApiOutlined,
  WechatOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;

function AdminSidebarMenu(props) {
  const { showSidebarStatus } = true;
  const { pathname } = props.location;
  let selectedMenu = null;
  if (pathname === "/masteradmin") {
    selectedMenu = "dashboard";
  } else if (pathname === "/masteradmin/users") {
    selectedMenu = "users";
  }
  if (selectedMenu !== null) {
    selectedMenu = selectedMenu.toString();
  }
  const handleMenuClick = (item) => {
    console.log(item);
    console.log(item);
    console.log(item);
    console.log(item);
    switch (item.key) {
      case "dashboard":
        props.history.push("/masteradmin");
        break;
      case "users":
        props.history.push("/masteradmin/users");
        break;
      default:
        break;
    }
  };

  let className = "sidebar-menu-open";
  if (showSidebarStatus) {
    className = "sidebar-menu-collapsed";
  }

  return (
    <Sider className={className} id="admin-sidebar-menu">
      <div>
        <Menu
          defaultSelectedKeys={[selectedMenu]}
          mode="inline"
          theme="dark"
          onClick={handleMenuClick}
          inlineCollapsed={true}
        >
          <Menu.Item key="dashboard" icon={<PieChartOutlined />}>
            Dashboard
          </Menu.Item>
          <Menu.Item key="users" icon={<AppstoreOutlined />}>
            Users
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
}

const mapStateToProps = (state) => ({
  //   showSidebarStatus: state.generic.sideBar.data || false,
});
const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminSidebarMenu)
);
