import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Layout, Steps } from "antd";
import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";
import Sample from "../images/sample-short-shout.png";
import InstagramSample from "../images/instagram-sample.png";
import RecentlyAddedUsers from "../components/RecentlyAddedUsers";

import FormResendVerificationLink from "../components/auth/FormResendVerificationLink";

import * as actions from "../redux/actions";

const { Content } = Layout;
const { Step } = Steps;

function PageResendVerificationLink(props) {
  const _doRegister = (data) => {
    props.registerRequest(data);
  };
  const _doResetPassword = (data) => {
    props.resetPasswordRequest(data);
  };
  return (
    <Layout className="top-level-layout" id="page-PageResendVerificationLink">
      <HeaderMain />
      <Content className="site-layout">
        <Row className="resendVerificationLink-root-wrapper">
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 10, offset: 6 }}>
            <FormResendVerificationLink />
          </Col>
        </Row>
      </Content>

      <FooterMain />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  login: state.auth.login,
  register: state.auth.register,
  resetPassword: state.auth.resetPassword,
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  registerRequest: (data) => dispatch(actions.registerRequest(data)),
  loginRequest: (data) => dispatch(actions.loginRequest(data)),
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  logoutRequest: (data) => dispatch(actions.logoutRequest(data)),
  resetPasswordRequest: (data) => dispatch(actions.resetPasswordRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageResendVerificationLink)
);
