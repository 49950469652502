// import ReactGA from "react-ga";
import ReactGA from "react-ga4";

ReactGA.initialize("G-YGQQMDXBEV");

const trackPage = () => {
  if (process.env.NODE_ENV === "development") {
  } else {
    const { location } = window;
    // console.log(location);
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    // ReactGA.set({ page: location.pathname });
    // ReactGA.pageview(location.pathname);
  }
};

const trackEvent = (data) => {
  if (process.env.NODE_ENV === "development") {
  } else {
    const { category, action, label, value } = data;
    console.log(data);
    ReactGA.event({
      category: category || "",
      action: action || "",
      label: label.toString() || "",
      value: label * 1 || 0,
    });
  }
};

export { trackPage, trackEvent };
