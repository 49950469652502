import React from "react";
import { Row, Col, Drawer } from "antd";

class SideDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, drawerWidth: "450" };
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentDidMount() {
    this.updateWindowDimensions();
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 767) {
      this.setState({
        drawerWidth: "100%",
      });
    } else {
      this.setState({
        drawerWidth: "450",
      });
    }
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    let placement = this.props.placement || "right";
    return (
      <Row>
        <Col xl={14} md={14} xs={24}>
          <Drawer
            width={this.state.drawerWidth}
            title={this.props.title}
            placement={placement}
            closable={true}
            onClose={this.props.onSideDrawerClose}
            visible={this.props.open}
          >
            {this.props.children}
          </Drawer>
        </Col>
      </Row>
    );
  }
}

export default SideDrawer;
