import React from "react";
import { Row, Col } from "antd";

import InstagramPages from "./InstagramPages";
import TwitterPages from "./TwitterPages";
import YoutubePages from "./YoutubePages";

function DashboardInfluencers(props) {
  const { instagramPages, twitterPages, youtubePages } = props;
  return (
    <>
      {/* {isEmailVerified ? null : <FormEmailVerification />}
      {!isUsernameAdded ? (
        <FormAddUserName />
      ) : ( */}
      <Row>
        <Col xl={24} md={24} xs={24} className="dashboard-influencers">
          <InstagramPages
            pages={instagramPages}
            doUpdateInfluencerInstagram={props.doUpdateInfluencerInstagram}
          />
          <TwitterPages
            pages={twitterPages}
            doUpdateInfluencerTwitter={props.doUpdateInfluencerTwitter}
          />
          <YoutubePages
            pages={youtubePages}
            doUpdateInfluencerYoutube={props.doUpdateInfluencerYoutube}
          />
          {/* <StatsInfluencers data={this.props.myStats} /> */}
        </Col>
        {/* <Col xl={10} md={10} xs={24} className="dashboard-sample-view"> </Col>  */}
      </Row>
    </>
  );
}

export default DashboardInfluencers;
