import React from "react";
import { Form, Input, Select, Button, AutoComplete, Spin } from "antd";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select
      style={{
        width: 70,
      }}
    >
      <Option value="86">+86</Option>
      <Option value="87">+87</Option>
    </Select>
  </Form.Item>
);

class FormAddNewShortShout extends React.Component {
  constructor() {
    super();
    this.state = {
      editLinkData: false,
    };
  }

  onFinish = (values) => {
    const { editLinkData } = this.props;
    if (editLinkData) {
      // do edit
      this.props.doUpdateLink({
        linkData: editLinkData,
        values: values,
      });
    } else {
      // do add new
      this.props.addNewLink(values);
    }
  };

  componentDidMount() {
    this.setState({
      editLinkData: this.props.editLinkData,
    });
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.editLinkData !== props.editLinkData) {
      return {
        editLinkData: props.editLinkData,
      };
    }
    return null;
  }

  render() {
    const { isLoading } = this.props.newLink;
    const isLoadingUpdate = this.props.updateLink.isLoading;
    const { editLinkData } = this.state;
    let initialValues = {
      linkType: editLinkData.linkType || "",
      title: editLinkData.title || "",
      url: editLinkData.url || "",
    };

    return (
      <Form
        {...formItemLayout}
        // form={form}
        name="register"
        onFinish={this.onFinish}
        initialValues={initialValues}
        scrollToFirstError
      >
        <Form.Item
          name="linkType"
          label="Link Type"
          rules={[{ required: true, message: "Select Link Type" }]}
        >
          <Select
            placeholder="Select link type"
            // onChange={onGenderChange}
            // allowClear
          >
            <Option value="other">Other</Option>
            <Option value="website">Website</Option>
            <Option value="facebook">Facebook</Option>
            <Option value="instagram">Instagram</Option>
            <Option value="twitter">Twitter</Option>
            <Option value="linkedin">Linkedin</Option>
            <Option value="youtube">Youtube</Option>
            <Option value="whatsapp">WhatsApp</Option>
            <Option value="github">Github</Option>
            <Option value="bitbucket">Bitbucket</Option>
            <Option value="pinterest">Pinterest</Option>
            <Option value="quora">Quora</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Enter title",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="url"
          label="URL"
          rules={[
            // {
            //   type: "url",
            //   message: "Enter a valid URL!",
            // },
            {
              required: true,
              message: "Enter URL",
            },
          ]}
        >
          <Input disabled={editLinkData === false ? "" : true} />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          {isLoading || isLoadingUpdate ? (
            <Spin />
          ) : (
            <Button type="primary" htmlType="submit">
              {this.props.addEditLinkFormButtonText}
            </Button>
          )}
        </Form.Item>
      </Form>
    );
  }
}

export default FormAddNewShortShout;
