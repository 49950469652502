import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Layout, Tabs, Button, Avatar } from "antd";
import {
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import AuthInstagram from "../components/influencers/AuthInstagram";
import PublicListInfluencersInstagram from "../components/publicListInfluencers/PublicListInfluencersInstagram";
import PublicListInfluencersTwitter from "../components/publicListInfluencers/PublicListInfluencersTwitter";

import PageTitle from "../components/PageTitle";

import {
  getUserInstagramLink,
  getUserTwitterLink,
  getUserYoutubeLink,
  getLoggedUser,
} from "../services";

import * as actions from "../redux/actions";

const { Content } = Layout;
const { TabPane } = Tabs;

function PublicListInfluencers(props) {
  const { publicInfluencerPages, loggedUserData } = props;

  const instagramPages =
    (publicInfluencerPages.data && publicInfluencerPages.data.instagramPages) ||
    [];
  const twitterPages =
    (publicInfluencerPages.data && publicInfluencerPages.data.twitterPages) ||
    [];
  const youtubePages =
    (publicInfluencerPages.data && publicInfluencerPages.data.youtubePages) ||
    [];

  let loggedUserInfo = getLoggedUser();

  const [hasInstagramPages, setHasInstagramPages] = useState(false);
  const [hasTwitterPages, setHasTwitterPages] = useState(false);
  const [activeTab, setActiveTab] = useState("tab-instagram");
  useEffect(() => {
    props.publicInfluencerPagesRequest();
  }, []);

  useEffect(() => {
    if (publicInfluencerPages && publicInfluencerPages.isLoading === false) {
      if (instagramPages.length > 0) {
        setHasInstagramPages(true);
        setActiveTab("tab-instagram");
      }
      if (twitterPages.length > 0) {
        setHasTwitterPages(true);
        if (instagramPages.length === 0) {
          setActiveTab("tab-twitter");
        }
      }
    }
  }, [instagramPages, twitterPages]);

  const renderTwitterPages = (pages) => {
    return pages.map((page, key) => {
      const {
        username,
        name,
        followers_count,
        follows_count,
        media_count,
        costPerPost,
        profile_picture_url,
      } = page;
      let twitterPageLink = getUserTwitterLink(username);

      let costPerPostFinal = (
        <Link to="login">
          <span>Sign in for details</span>
        </Link>
      );
      if (loggedUserData) {
        costPerPostFinal = "-NA-";
        if (costPerPost && costPerPost > 0) {
          costPerPostFinal = "₹" + costPerPost;
        }
      }

      return (
        <Row key={key} className="twitter-page">
          <Col xs={12} md={2} className="details-wrap top-layer">
            <div className="label">Twitter</div>
            <div className="value">
              <TwitterOutlined />
            </div>
          </Col>
          <Col xs={12} md={3} className="details-wrap top-layer">
            {/* <div className="label">{username}</div> */}
            <div className="value">
              <Avatar src={profile_picture_url} />
            </div>
          </Col>
          <Col xs={8} md={3} className="details-wrap">
            <div className="label">Tweets</div>
            <div className="value">{media_count}</div>
          </Col>
          <Col xs={8} md={4} className="details-wrap">
            <div className="label">Followers</div>
            <div className="value">{followers_count}</div>
          </Col>
          <Col xs={8} md={4} className="details-wrap">
            <div className="label">Following</div>
            <div className="value">{follows_count}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Cost Per Post</div>
            <div className="value">{costPerPostFinal}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Contact</div>
            <div className="value">
              {!loggedUserInfo ? (
                <Link to="login">
                  <span>Sign in for details</span>
                </Link>
              ) : (
                <a href={twitterPageLink} target="_BLANK">
                  Contact
                </a>
              )}
            </div>
          </Col>
        </Row>
      );
    });
  };

  const renderInstagramPages = (pages) => {
    return pages.map((page, key) => {
      const {
        username,
        name,
        followers_count,
        follows_count,
        media_count,
        costPerPost,
        profile_picture_url,
      } = page;
      let instagramPageLink = getUserInstagramLink(username);

      let costPerPostFinal = (
        <Link to="login">
          <span>Sign in for details</span>
        </Link>
      );
      if (loggedUserData) {
        costPerPostFinal = "-NA-";
        if (costPerPost && costPerPost > 0) {
          costPerPostFinal = "₹" + costPerPost;
        }
      }
      return (
        <Row key={key} className="instagram-page">
          <Col xs={12} md={2} className="details-wra top-layer">
            <div className="label">Instagram</div>
            <div className="value">
              <InstagramOutlined />
            </div>
          </Col>
          <Col xs={12} md={3} className="details-wrap top-layer">
            {/* <div className="label">{username}</div> */}
            <div className="value">
              <Avatar src={profile_picture_url} />
            </div>
          </Col>
          <Col xs={8} md={3} className="details-wrap">
            <div className="label">Posts</div>
            <div className="value">{media_count}</div>
          </Col>
          <Col xs={8} md={4} className="details-wrap">
            <div className="label">Followers</div>
            <div className="value">{followers_count}</div>
          </Col>
          <Col xs={8} md={4} className="details-wrap">
            <div className="label">Following</div>
            <div className="value">{follows_count}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Cost Per Post</div>
            <div className="value">{costPerPostFinal}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Contact</div>
            <div className="value">
              <div className="value">
                {!loggedUserInfo ? (
                  <Link to="login">
                    <span>Sign in for details</span>
                  </Link>
                ) : (
                  <a href={instagramPageLink} target="_BLANK">
                    Contact
                  </a>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    });
  };

  const renderYoutubePages = (pages) => {
    return pages.map((page, key) => {
      const {
        id,
        username,
        name,
        followers_count,
        follows_count,
        media_count,
        costPerPost,
        profile_picture_url,
        videos_view_count,
      } = page;
      let youtubePageLink = getUserYoutubeLink(id);

      let costPerPostFinal = (
        <Link to="login">
          <span>Sign in for details</span>
        </Link>
      );
      if (loggedUserData) {
        costPerPostFinal = "-NA-";
        if (costPerPost && costPerPost > 0) {
          costPerPostFinal = "₹" + costPerPost;
        }
      }
      return (
        <Row key={key} className="youtube-page">
          <Col xs={12} md={2} className="details-wrap top-layer">
            <div className="label">Youtube</div>
            <div className="value">
              <YoutubeOutlined />
            </div>
          </Col>
          <Col xs={12} md={3} className="details-wrap top-layer">
            <div className="label">{username}</div>
            <div className="value">
              <Avatar src={profile_picture_url} />
            </div>
          </Col>
          <Col xs={8} md={3} className="details-wrap">
            <div className="label">Videos</div>
            <div className="value">{media_count}</div>
          </Col>
          <Col xs={8} md={4} className="details-wrap">
            <div className="label">Subscribers</div>
            <div className="value">{followers_count}</div>
          </Col>
          <Col xs={8} md={4} className="details-wrap">
            <div className="label">View Count</div>
            <div className="value">{videos_view_count}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Cost Per Post</div>
            <div className="value">{costPerPostFinal}</div>
          </Col>
          <Col xs={12} md={4} className="details-wrap">
            <div className="label">Contact</div>
            <div className="value">
              <div className="value">
                {!loggedUserInfo ? (
                  <Link to="login">
                    <span>Sign in for details</span>
                  </Link>
                ) : (
                  <a href={youtubePageLink} target="_BLANK">
                    Contact
                  </a>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    });
  };

  return (
    <Layout id="page-public-influencers">
      <HeaderMain />
      <Content className="site-layout">
        <PageTitle title="Influencers List" />
        <br />

        {/* <Tabs activeKey={activeTab} className="dashboard-wrapper-tabs" centered>
          {hasInstagramPages ? (
            <TabPane
              tab={
                <span onClick={() => setActiveTab("tab-instagram")}>
                  <InstagramOutlined />
                  Instagram Influencers
                </span>
              }
              key="tab-instagram"
            >
              <PublicListInfluencersInstagram
                loggedUserData={loggedUserData}
                instagramPages={instagramPages}
              />
            </TabPane>
          ) : null}
          {hasTwitterPages ? (
            <TabPane
              tab={
                <span onClick={() => setActiveTab("tab-twitter")}>
                  <InstagramOutlined />
                  Twitter Influencers
                </span>
              }
              key="tab-twitter"
            >
              <PublicListInfluencersTwitter
                loggedUserData={loggedUserData}
                twitterPages={twitterPages}
              />
            </TabPane>
          ) : null}
        </Tabs> */}

        <div className="lists-block">
          {renderYoutubePages(youtubePages)}

          {renderTwitterPages(twitterPages)}

          {renderInstagramPages(instagramPages)}
        </div>

        {/* <PublicListInfluencersInstagram
          loggedUserData={loggedUserData}
          instagramPages={instagramPages}
        />

        <PublicListInfluencersTwitter
          loggedUserData={loggedUserData}
          twitterPages={twitterPages}
        /> */}

        {/* <div className="more-details">
          <Button type="link">Login For More..</Button>
        </div> */}
      </Content>
      <FooterMain />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  login: state.auth.login,
  loggedUserData: state.auth.login.data || false,
  // instagramPages: state.influencers.myInfluencerPages.data.instagramPages || [],
  publicInfluencerPages: state.influencers.publicInfluencerPages || false,
});

const mapDispatchToProps = (dispatch) => ({
  publicInfluencerPagesRequest: (data) =>
    dispatch(actions.publicInfluencerPagesRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PublicListInfluencers)
);
