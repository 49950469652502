import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getLoggedUser } from "../../services";

export default (ComposedComponent) => {
  class Authentication extends Component {
    componentWillMount() {
      let userInfo = getLoggedUser();

      if (userInfo === false) {
        this.props.history.push("/");
      }
      // if (
      //   !this.props.loggedUserData ||
      //   (this.props.loggedUserData.role !== "user" &&
      //     this.props.loggedUserData.role !== "masteradmin")
      // ) {
      //   this.props.history.push(`/`);
      // }
    }

    componentWillUpdate(nextProps) {
      let userInfo = getLoggedUser();

      if (userInfo === false) {
        this.props.history.push("/");
      }
      // if (
      //   !nextProps.loggedUserData ||
      //   (nextProps.loggedUserData.role !== "user" &&
      //     nextProps.loggedUserData.role !== "masteradmin")
      // ) {
      //   this.props.history.push(`/`);
      // }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      loggedUserData: state.auth.login.data || false,
    };
  }

  return withRouter(connect(mapStateToProps)(Authentication));
};
