import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  newLink: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  myLinks: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  deleteLink: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  updateLinkCount: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  updateLinkStatus: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  updateLink: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
};

/* NEW LINK */
const newLinkRequest = (state, action) =>
  update(state, {
    newLink: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const newLinkSuccess = (state, action) =>
  update(state, {
    newLink: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Link added successfully!!" },
      data: { $set: action.payload },
    },
  });

const newLinkError = (state, action) =>
  update(state, {
    newLink: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });
/* NEW LINK */

/* MY LINKS */
const myLinksRequest = (state, action) =>
  update(state, {
    myLinks: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const myLinksSuccess = (state, action) =>
  update(state, {
    myLinks: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const myLinksError = (state, action) =>
  update(state, {
    myLinks: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });
/* NEW LINK */

/* delete LINK */
const deleteLinkRequest = (state, action) =>
  update(state, {
    deleteLink: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const deleteLinkSuccess = (state, action) =>
  update(state, {
    deleteLink: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Link added successfully!!" },
      data: { $set: action.payload },
    },
  });

const deleteLinkError = (state, action) =>
  update(state, {
    deleteLink: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });
/* delete LINK */

/* update LINK count */
const updateLinkCountRequest = (state, action) =>
  update(state, {
    updateLinkCount: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const updateLinkCountSuccess = (state, action) =>
  update(state, {
    updateLinkCount: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Link added successfully!!" },
      data: { $set: action.payload },
    },
  });

const updateLinkCountError = (state, action) =>
  update(state, {
    updateLinkCount: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });
/* update LINK count */

/* update LINK status */
const updateLinkStatusRequest = (state, action) =>
  update(state, {
    updateLinkStatus: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const updateLinkStatusSuccess = (state, action) =>
  update(state, {
    updateLinkStatus: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Link status updated successfully!!" },
      data: { $set: action.payload },
    },
  });

const updateLinkStatusError = (state, action) =>
  update(state, {
    updateLinkStatus: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });
/* update LINK status */

/* update LINK */
const updateLinkRequest = (state, action) =>
  update(state, {
    updateLink: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const updateLinkSuccess = (state, action) =>
  update(state, {
    updateLink: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Link added successfully!!" },
      data: { $set: action.payload },
    },
  });

const updateLinkError = (state, action) =>
  update(state, {
    updateLink: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });
/* update LINK */

export default handleActions(
  {
    [constants.NEW_LINK_REQUEST]: newLinkRequest,
    [constants.NEW_LINK_SUCCESS]: newLinkSuccess,
    [constants.NEW_LINK_ERROR]: newLinkError,

    [constants.MY_LINKS_SUCCESS]: myLinksRequest,
    [constants.MY_LINKS_SUCCESS]: myLinksSuccess,
    [constants.MY_LINKS_ERROR]: myLinksError,

    [constants.DELETE_LINK_REQUEST]: deleteLinkRequest,
    [constants.DELETE_LINK_SUCCESS]: deleteLinkSuccess,
    [constants.DELETE_LINK_ERROR]: deleteLinkError,

    [constants.UPDATE_LINK_CLICK_COUNT_REQUEST]: updateLinkCountRequest,
    [constants.UPDATE_LINK_CLICK_COUNT_SUCCESS]: updateLinkCountSuccess,
    [constants.UPDATE_LINK_CLICK_COUNT_ERROR]: updateLinkCountError,

    [constants.UPDATE_LINK_STATUS_REQUEST]: updateLinkStatusRequest,
    [constants.UPDATE_LINK_STATUS_SUCCESS]: updateLinkStatusSuccess,
    [constants.UPDATE_LINK_STATUS_ERROR]: updateLinkStatusError,

    [constants.UPDATE_LINK_REQUEST]: updateLinkRequest,
    [constants.UPDATE_LINK_SUCCESS]: updateLinkSuccess,
    [constants.UPDATE_LINK_ERROR]: updateLinkError,
  },
  initialState
);
