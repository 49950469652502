import React from "react";
import { Button, Card, Col, Row } from "antd";
import {
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

import { GoogleLogin } from "react-google-login";

import FacebookLogin from "react-facebook-login";

import { getYoutubeAuthorizationLink } from "../../services";

function AuthInstagram(props) {
  const responseFacebook = (response) => {
    props.saveInstagramAccounts(response);
  };

  const onErrorGoogle = (response) => {
    // console.log(response);
  };

  const authYoutubeChannel = () => {
    let url = getYoutubeAuthorizationLink();
    console.log(url);
    window.location.replace(url);
  };

  return (
    <div>
      <div className="site-card-wrapper">
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Card title="Connect Youtube Channel" bordered={false}>
              <div>
                Connect Your Youtube Channel
                <br />
                <br />
                Authorizing Youtube will allow to get your youtube videos count,
                Subscribers count, watch count.
                <br />
                <br />
                In order to feature your profile as influencer, please connect
                youtube below.
                <br />
                <br />
                <Button icon={<YoutubeOutlined />} onClick={authYoutubeChannel}>
                  Fetch My Youtube Channels
                </Button>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card title="Connect Twitter" bordered={false}>
              <div>
                Connect Your Twitter Account
                <br />
                <br />
                Authorizing Twitter will allow to get your twitter followers,
                following and tweets count.
                <br />
                <br />
                In order to feature your profile as influencer, please connect
                Twitter below.
                <br />
                <br />
                <Button
                  icon={<TwitterOutlined />}
                  onClick={props.initiateTwitterAuth}
                >
                  Auth Twitter
                </Button>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            {/* <Card title="Connect Instagram" bordered={false}>
              Connect Your Instagram Account
              <br />
              <br />
              Authorizing Instagram will allow to get your instagram followers,
              following and posts count.
              <br />
              <br />
              In order to feature your profile as influencer, please connect
              Instagram below.
              <br />
              <br />
              <FacebookLogin
                appId="2947741728831520"
                autoLoad={false}
                fields="name,email,picture"
                scope="public_profile,instagram_basic,pages_show_list"
                callback={responseFacebook}
                cssClass="my-facebook-button-class"
                textButton={
                  <>
                    <InstagramOutlined />
                    Connect Instagram
                  </>
                }
              />
            </Card> */}
          </Col>
        </Row>
      </div>

      <br />
      <br />
      <br />

      <br />
      <br />
      <br />
    </div>
  );
}

export default AuthInstagram;
