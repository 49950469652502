const constants = {
  SHOW_PROGRESS_BAR: "SHOW_PROGRESS_BAR",

  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  IS_LOGGEDIN_USER: "IS_LOGGEDIN_USER",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_REQUEST_SUCCESS: "LOGOUT_REQUEST_SUCCESS",
  LOGOUT_REQUEST_ERROR: "LOGOUT_REQUEST_ERROR",

  VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_ERROR: "VERIFY_EMAIL_ERROR",

  SEND_EMAIL_VERIFY_CODE_REQUEST: "SEND_EMAIL_VERIFY_CODE_REQUEST",
  SEND_EMAIL_VERIFY_CODE_SUCCESS: "SEND_EMAIL_VERIFY_CODE_SUCCESS",
  SEND_EMAIL_VERIFY_CODE_ERROR: "SEND_EMAIL_VERIFY_CODE_ERROR",

  NEW_LINK_REQUEST: "NEW_LINK_REQUEST",
  NEW_LINK_SUCCESS: "NEW_LINK_SUCCESS",
  NEW_LINK_ERROR: "NEW_LINK_ERROR",

  UPDATE_LINK_REQUEST: "UPDATE_LINK_REQUEST",
  UPDATE_LINK_SUCCESS: "UPDATE_LINK_SUCCESS",
  UPDATE_LINK_ERROR: "UPDATE_LINK_ERROR",

  MY_LINKS_REQUEST: "MY_LINKS_REQUEST",
  MY_LINKS_SUCCESS: "MY_LINKS_SUCCESS",
  MY_LINKS_ERROR: "MY_LINKS_ERROR",

  GET_PUBLIC_SHORT_SHOUT_REQUEST: "GET_PUBLIC_SHORT_SHOUT_REQUEST",
  GET_PUBLIC_SHORT_SHOUT_SUCCESS: "GET_PUBLIC_SHORT_SHOUT_SUCCESS",
  GET_PUBLIC_SHORT_SHOUT_ERROR: "GET_PUBLIC_SHORT_SHOUT_ERROR",

  DELETE_LINK_REQUEST: "DELETE_LINK_REQUEST",
  DELETE_LINK_SUCCESS: "DELETE_LINK_SUCCESS",
  DELETE_LINK_ERROR: "DELETE_LINK_ERROR",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",

  UPDATE_LINK_CLICK_COUNT_REQUEST: "UPDATE_LINK_CLICK_COUNT_REQUEST",
  UPDATE_LINK_CLICK_COUNT_SUCCESS: "UPDATE_LINK_CLICK_COUNT_SUCCESS",
  UPDATE_LINK_CLICK_COUNT_ERROR: "UPDATE_LINK_CLICK_COUNT_ERROR",

  UPDATE_LINK_STATUS_REQUEST: "UPDATE_LINK_STATUS_REQUEST",
  UPDATE_LINK_STATUS_SUCCESS: "UPDATE_LINK_STATUS_SUCCESS",
  UPDATE_LINK_STATUS_ERROR: "UPDATE_LINK_STATUS_ERROR",

  GET_RECENTLY_ADDED_USERS_REQUEST: "GET_RECENTLY_ADDED_USERS_REQUEST",
  GET_RECENTLY_ADDED_USERS_SUCCESS: "GET_RECENTLY_ADDED_USERS_SUCCESS",
  GET_RECENTLY_ADDED_USERS_ERROR: "GET_RECENTLY_ADDED_USERS_ERROR",

  MY_STATS_REQUEST: "MY_STATS_REQUEST",
  MY_STATS_SUCCESS: "MY_STATS_SUCCESS",
  MY_STATS_ERROR: "MY_STATS_ERROR",

  ADMIN_GET_LATEST_USERS_REQUEST: "ADMIN_GET_LATEST_USERS_REQUEST",
  ADMIN_GET_LATEST_USERS_SUCCESS: "ADMIN_GET_LATEST_USERS_SUCCESS",
  ADMIN_GET_LATEST_USERS_ERROR: "ADMIN_GET_LATEST_USERS_ERROR",

  ADMIN_GET_LATEST_LINKS_REQUEST: "ADMIN_GET_LATEST_LINKS_REQUEST",
  ADMIN_GET_LATEST_LINKS_SUCCESS: "ADMIN_GET_LATEST_LINKS_SUCCESS",
  ADMIN_GET_LATEST_LINKS_ERROR: "ADMIN_GET_LATEST_LINKS_ERROR",

  ADMIN_GET_COMPLETE_STATS_REQUEST: "ADMIN_GET_COMPLETE_STATS_REQUEST",
  ADMIN_GET_COMPLETE_STATS_SUCCESS: "ADMIN_GET_COMPLETE_STATS_SUCCESS",
  ADMIN_GET_COMPLETE_STATS_ERROR: "ADMIN_GET_COMPLETE_STATS_ERROR",

  ADMIN_SEND_DO_VERIFICATION_EMAIL_REQUEST:
    "ADMIN_SEND_DO_VERIFICATION_EMAIL_REQUEST",
  ADMIN_SEND_DO_VERIFICATION_EMAIL_SUCCESS:
    "ADMIN_SEND_DO_VERIFICATION_EMAIL_SUCCESS",
  ADMIN_SEND_DO_VERIFICATION_EMAIL_ERROR:
    "ADMIN_SEND_DO_VERIFICATION_EMAIL_ERROR",

  ADD_USERNAME_REQUEST: "ADD_USERNAME_REQUEST",
  ADD_USERNAME_SUCCESS: "ADD_USERNAME_SUCCESS",
  ADD_USERNAME_ERROR: "ADD_USERNAME_ERROR",

  GET_REFRESH_TOKEN_REQUEST: "GET_REFRESH_TOKEN_REQUEST",
  GET_REFRESH_TOKEN_SUCCESS: "GET_REFRESH_TOKEN_SUCCESS",
  GET_REFRESH_TOKEN_ERROR: "GET_REFRESH_TOKEN_ERROR",

  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR: "UPDATE_PASSWORD_ERROR",

  UPLOAD_AVATAR_REQUEST: "UPLOAD_AVATAR_REQUEST",
  UPLOAD_AVATAR_SUCCESS: "UPLOAD_AVATAR_SUCCESS",
  UPLOAD_AVATAR_ERROR: "UPLOAD_AVATAR_ERROR",

  MY_AVATAR_REQUEST: "MY_AVATAR_REQUEST",
  MY_AVATAR_SUCCESS: "MY_AVATAR_SUCCESS",
  MY_AVATAR_ERROR: "MY_AVATAR_ERROR",

  SAVE_INSTAGRAM_ACCOUNTS_REQUEST: "SAVE_INSTAGRAM_ACCOUNTS_REQUEST",
  SAVE_INSTAGRAM_ACCOUNTS_SUCCESS: "SAVE_INSTAGRAM_ACCOUNTS_SUCCESS",
  SAVE_INSTAGRAM_ACCOUNTS_ERROR: "SAVE_INSTAGRAM_ACCOUNTS_ERROR",

  MY_INFLUENCER_PAGES_REQUEST: "MY_INFLUENCER_PAGES_REQUEST",
  MY_INFLUENCER_PAGES_SUCCESS: "MY_INFLUENCER_PAGES_SUCCESS",
  MY_INFLUENCER_PAGES_ERROR: "MY_INFLUENCER_PAGES_ERROR",

  PUBLIC_INFLUENCER_PAGES_REQUEST: "PUBLIC_INFLUENCER_PAGES_REQUEST",
  PUBLIC_INFLUENCER_PAGES_SUCCESS: "PUBLIC_INFLUENCER_PAGES_SUCCESS",
  PUBLIC_INFLUENCER_PAGES_ERROR: "PUBLIC_INFLUENCER_PAGES_ERROR",

  UPDATE_INFLUENCER_INSTAGRAM_REQUEST: "UPDATE_INFLUENCER_INSTAGRAM_REQUEST",
  UPDATE_INFLUENCER_INSTAGRAM_SUCCESS: "UPDATE_INFLUENCER_INSTAGRAM_SUCCESS",
  UPDATE_INFLUENCER_INSTAGRAM_ERROR: "UPDATE_INFLUENCER_INSTAGRAM_ERROR",

  TWITTER_AUTH_REQUEST: "TWITTER_AUTH_REQUEST",
  TWITTER_AUTH_SUCCESS: "TWITTER_AUTH_SUCCESS",
  TWITTER_AUTH_ERROR: "TWITTER_AUTH_ERROR",

  TWITTER_AUTH_VERIFY_REQUEST: "TWITTER_AUTH_VERIFY_REQUEST",
  TWITTER_AUTH_VERIFY_SUCCESS: "TWITTER_AUTH_VERIFY_SUCCESS",
  TWITTER_AUTH_VERIFY_ERROR: "TWITTER_AUTH_VERIFY_ERROR",

  UPDATE_INFLUENCER_TWITTER_REQUEST: "UPDATE_INFLUENCER_TWITTER_REQUEST",
  UPDATE_INFLUENCER_TWITTER_SUCCESS: "UPDATE_INFLUENCER_TWITTER_SUCCESS",
  UPDATE_INFLUENCER_TWITTER_ERROR: "UPDATE_INFLUENCER_TWITTER_ERROR",

  NEW_CAMPAIGN_REQUEST: "NEW_CAMPAIGN_REQUEST",
  NEW_CAMPAIGN_SUCCESS: "NEW_CAMPAIGN_SUCCESS",
  NEW_CAMPAIGN_ERROR: "NEW_CAMPAIGN_ERROR",

  MY_CAMPAIGNS_REQUEST: "MY_CAMPAIGNS_REQUEST",
  MY_CAMPAIGNS_SUCCESS: "MY_CAMPAIGNS_SUCCESS",
  MY_CAMPAIGNS_ERROR: "MY_CAMPAIGNS_ERROR",

  MY_CAMPAIGN_REQUEST: "MY_CAMPAIGN_REQUEST",
  MY_CAMPAIGN_SUCCESS: "MY_CAMPAIGN_SUCCESS",
  MY_CAMPAIGN_ERROR: "MY_CAMPAIGN_ERROR",

  UPDATE_MY_CAMPAIGN_REQUEST: "UPDATE_MY_CAMPAIGN_REQUEST",
  UPDATE_MY_CAMPAIGN_SUCCESS: "UPDATE_MY_CAMPAIGN_SUCCESS",
  UPDATE_MY_CAMPAIGN_ERROR: "UPDATE_MY_CAMPAIGN_ERROR",

  ACTIVE_CAMPAIGNS_REQUEST: "ACTIVE_CAMPAIGNS_REQUEST",
  ACTIVE_CAMPAIGNS_SUCCESS: "ACTIVE_CAMPAIGNS_SUCCESS",
  ACTIVE_CAMPAIGNS_ERROR: "ACTIVE_CAMPAIGNS_ERROR",

  APPLY_CAMPAIGN_REQUEST: "APPLY_CAMPAIGN_REQUEST",
  APPLY_CAMPAIGN_SUCCESS: "APPLY_CAMPAIGN_SUCCESS",
  APPLY_CAMPAIGN_ERROR: "APPLY_CAMPAIGN_ERROR",

  SAVE_YOUTUBE_ACCOUNTS_REQUEST: "SAVE_YOUTUBE_ACCOUNTS_REQUEST",
  SAVE_YOUTUBE_ACCOUNTS_SUCCESS: "SAVE_YOUTUBE_ACCOUNTS_SUCCESS",
  SAVE_YOUTUBE_ACCOUNTS_ERROR: "SAVE_YOUTUBE_ACCOUNTS_ERROR",

  UPDATE_INFLUENCER_YOUTUBE_REQUEST: "UPDATE_INFLUENCER_YOUTUBE_REQUEST",
  UPDATE_INFLUENCER_YOUTUBE_SUCCESS: "UPDATE_INFLUENCER_YOUTUBE_SUCCESS",
  UPDATE_INFLUENCER_YOUTUBE_ERROR: "UPDATE_INFLUENCER_YOUTUBE_ERROR",
};

export default constants;
