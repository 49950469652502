import React, { useState } from "react";
import { connect } from "react-redux";

import { Upload, Button } from "antd";
import ImgCrop from "antd-img-crop";

import { showNotification } from "../../services";
import * as actions from "../../redux/actions";

const FormUploadAvtar = (props) => {
  const [fileList, setFileList] = useState([
    // {
    //   uid: "-1",
    //   name: "image.png",
    //   status: "done",
    //   url:
    //     "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    // },
  ]);

  //   const onChange = (info) => {
  //     console.log("onchange----");
  //     console.log(info);
  //     // setFileList(newFileList);
  //   };

  //   const onPreview = async (file) => {
  //     console.log("onPreview----");
  //     let src = file.url;
  //     if (!src) {
  //       src = await new Promise((resolve) => {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(file.originFileObj);
  //         reader.onload = () => resolve(reader.result);
  //       });
  //     }
  //     const image = new Image();
  //     image.src = src;
  //     const imgWindow = window.open(src);
  //     imgWindow.document.write(image.outerHTML);
  //   };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const onFileCropped = (info) => {
    getBase64(info.file, (imageUrl) => {
      props.uploadAvatarRequest({
        shortshoutAvatar: imageUrl,
      });
    });
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      showNotification({
        type: "warning",
        title: "You can only upload JPG/PNG file!",
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      showNotification({
        type: "warning",
        title: "Image must smaller than 2MB!",
      });
    }
    return isJpgOrPng && isLt2M;
  }

  return (
    <ImgCrop rotate>
      <Upload
        customRequest={onFileCropped}
        beforeUpload={beforeUpload}
        // maxCount="1"
        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        // listType="picture-card"
        fileList={[]}
        // onChange={onChange}
        // onPreview={onPreview}
      >
        {/* {fileList.length < 1 && "+ Upload"} */}
        <br />
        <Button type="primary" className="login-form-button">
          Upload Avatar
        </Button>
        <br />
      </Upload>
    </ImgCrop>
  );
};

const mapStateToProps = (state) => ({
  // addUsername: state.auth.addUsername,
});

const mapDispatchToProps = (dispatch) => ({
  uploadAvatarRequest: (data) => dispatch(actions.uploadAvatarRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormUploadAvtar);
