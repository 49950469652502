import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { SyncOutlined } from "@ant-design/icons";

import * as actions from "../redux/actions";

const antIcon = <SyncOutlined style={{ fontSize: 40 }} spin />;

function ProgressBarWrapper(props) {
  const [progressBarStatus, setProgressBarStatus] = useState(false);
  useEffect(() => {
    setProgressBarStatus(props.progressBarStatus);
  }, [props.progressBarStatus]);
  return (
    <>
      {progressBarStatus ? (
        <div id="progress-bar-wrapper">
          <Spin
            indicator={antIcon}
            className="spinner"
            size="large"
            tip="Loading..."
          />
        </div>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  progressBarStatus: state.generic.progressBar.data || false,
});
const mapDispatchToProps = (dispatch) => ({
  showProgressBar: (data) => dispatch(actions.showProgressBar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBarWrapper);
