import React, { useEffect, useState } from "react";
import { Layout, Table, Input, Row, Col, Modal } from "antd";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";

// import PageTitle from "../components/PageTitle";
// import HeaderMain from "../components/HeaderMain";

import * as actions from "../../redux/actions";
import { getUserShortShoutUrl } from "../../services";

const { Content } = Layout;

function AdminLinksList(props) {
  const { loggedUserData, defaultOrganization, organizationInvoicesList } =
    props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [searchText, setSearchText] = useState("");
  const [linksList, setLinksList] = useState([]);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [tableDataSourceBackup, setTableDataSourceBackup] = useState([]);

  useEffect(() => {
    props.adminGetLatestLinksRequest();
    // if (loggedUserData.uid && defaultOrganization.organizationId) {
    //   props.getOrganizationInvoicesRequest({
    //     organizationId: defaultOrganization.organizationId,
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserData]);

  useEffect(() => {
    setLinksList(props.linksList);
  }, [props.linksList]);

  useEffect(() => {
    const tableData = [];

    linksList &&
      linksList.forEach((link, i) => {
        const {
          //   _id,
          //   username,
          //   email,
          //   name,
          //   status,
          //   role,
          createdAt,
          //   isEmailVerified,
          updatedAt,
          title,
          linkType,
          //   user_links,
          //   user_stats,
          //   user_links_deleted,
          //   isSendDoVerificationEmailRequest,
          url,
          user_details,
        } = link;

        // let shortshoutUrl = getUserShortShoutUrl(user);

        // let statusIsEmailVerified = isEmailVerified;

        let createdAtVal = moment(createdAt).format("lll");
        let updatedOn = moment(updatedAt).format("lll");

        let userShortShoutUrl = getUserShortShoutUrl(user_details);

        let username = user_details.username || "";
        let user_email = user_details.email || "";
        let isEmailVerified = user_details.isEmailVerified || false;
        let user_status = user_details.status || "";

        // let countPageImpressions =
        //   (user_stats && user_stats.pageViewsCount) || 0;
        // let countLinkClicks = (user_stats && user_stats.linksClickedCount) || 0;
        // let countTotalLinks = user_links.length || 0;

        link = {
          // ...user,
          //   shortshoutUrl: shortshoutUrl,
          serialnumber: i + 1,
          //   statusIsEmailVerified: statusIsEmailVerified,
          createdAt: createdAtVal,
          updatedAt: updatedOn,
          link_url: url,
          link_title: title,
          link_type: linkType,
          userShortShoutUrl: userShortShoutUrl,
          username: username,
          user_email: user_email,
          user_isEmailVerified: isEmailVerified,
          user_status: user_status,
          //   countPageImpressions: countPageImpressions,
          //   countLinkClicks: countLinkClicks,
          //   countTotalLinks: countTotalLinks,
        };

        tableData.push(link);
      });

    if (tableData.length > 0) {
      setTableDataSource(tableData);
      setTableDataSourceBackup(tableData);
    }
  }, [linksList]);

  //   const showErrorsModal = (row) => {
  //     const { failureReasons, originalRow } = row;
  //     const { file } = originalRow;
  //     let modalContent = failureReasons.map((f) => {
  //       return <div>{f}</div>;
  //     });
  //     setModalContent({
  //       title: file.name || "",
  //       content: modalContent,
  //     });
  //     setIsModalVisible(true);
  //   };

  const columns = [
    {
      title: "#",
      dataIndex: "serialnumber",
      key: "serialnumber",
      width: 20,
    },
    {
      title: "Added On",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 40,
    },
    {
      title: "Updated On",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 40,
    },
    {
      title: "Link Type",
      dataIndex: "link_type",
      key: "link_type",
      width: 20,
    },
    {
      title: "Link Title",
      dataIndex: "link_title",
      key: "link_title",
      width: 20,
    },
    {
      title: "Link",
      dataIndex: "link_url",
      key: "link_url",
      width: 50,
      render: (text, row) => {
        const { link_url } = row;
        return (
          <a href={link_url} target="_BLANK">
            {link_url}
          </a>
        );
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 40,
      render: (text, row) => {
        const { username, userShortShoutUrl } = row;
        return (
          <a href={userShortShoutUrl} target="_BLANK">
            {username}
          </a>
        );
      },
    },
    {
      title: "Email Verified",
      dataIndex: "user_isEmailVerified",
      key: "user_isEmailVerified",
      width: 20,
      render: (text, row) => {
        const { user_isEmailVerified } = row;
        return user_isEmailVerified ? "Y" : "N";
      },
    },
    {
      title: "User Email",
      dataIndex: "user_email",
      key: "user_email",
      width: 50,
    },
  ];

  //   const onSearchTextChange = (e) => {
  //     let txt = e.target.value;
  //     if (txt.trim() === "") {
  //       setTableDataSource(tableDataSourceBackup);
  //     } else {
  //       setSearchText(txt);
  //       let filterData = [];
  //       let keysForSearchMatch = [
  //         "due_date",
  //         "invoice_date",
  //         "invoice_id",
  //         "supplier_name",
  //       ];
  //       txt = txt.toLowerCase();
  //       filterData = tableDataSourceBackup.filter(function (invoice) {
  //         return keysForSearchMatch.some((key) =>
  //           String(invoice[key]).toLowerCase().includes(txt)
  //         );
  //       });
  //       setTableDataSource(filterData);
  //     }
  //   };

  //   const renderHeader = () => {
  //     return (
  //       <Row>
  //         <Col md={12} lg={8}>
  //           <Input placeholder="Search..." onChange={onSearchTextChange} />
  //         </Col>
  //         <Col md={12} lg={16}></Col>
  //       </Row>
  //     );
  //   };

  return (
    <div className="table-content">
      <Table
        id="table-invoices"
        // title={() => renderHeader()}
        rowClassName={(record, index) =>
          record.isEmailVerified ? "email-verified" : "email-not-verified"
        }
        showHeader={true}
        tableLayout="fixed"
        pagination={{
          pageSize: 200,
          size: "small",
          showSizeChanger: false,
        }}
        size="small"
        bordered={true}
        columns={columns}
        dataSource={tableDataSource}
        scroll={{ x: 1500 }}
      />

      <Modal
        closable={false}
        title={modalContent.title || ""}
        visible={isModalVisible}
        cancelText={() => null}
        onOk={() => setIsModalVisible(false)}
        // onCancel={handleCancel}
      >
        {modalContent.content || ""}
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  // newLink: state.links.newLink || false,
  loggedUserData: state.auth.login.data || false,
  usersList: state.admin.usersList.data || [],
  linksList: state.admin.linksList.data || [],
  admin: state.admin,
  // myLinks: state.links.myLinks.data || [],
  // myStats: state.dashboard.myStats,
  // updateLink: state.links.updateLink || false,
});

const mapDispatchToProps = (dispatch) => ({
  adminGetLatestUsersRequest: (data) =>
    dispatch(actions.adminGetLatestUsersRequest(data)),
  adminGetLatestLinksRequest: (data) =>
    dispatch(actions.adminGetLatestLinksRequest(data)),
  adminGetCompleteStatsRequest: (data) =>
    dispatch(actions.adminGetCompleteStatsRequest(data)),
  adminSendDoVerificationEmailRequest: (data) =>
    dispatch(actions.adminSendDoVerificationEmailRequest(data)),
  // updateLinkRequest: (data) => dispatch(actions.updateLinkRequest(data)),
  // myLinksRequest: (data) => dispatch(actions.myLinksRequest(data)),
  // deleteLinkRequest: (data) => dispatch(actions.deleteLinkRequest(data)),
  // updateLinkStatusRequest: (data) =>
  //   dispatch(actions.updateLinkStatusRequest(data)),
  // myStatsRequest: (data) => dispatch(actions.myStatsRequest(data)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminLinksList)
);
