import React, { useState } from "react";
import {
  Form,
  Input,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  Switch,
} from "antd";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const FormEditCampaign = (props) => {
  const { campaign } = props;
  const { status, platform, name, description, budget } = campaign;
  let campaignStatus = status === "active" ? true : false;

  const [form] = Form.useForm();

  console.log("name-----" + name);

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    let status = values.status;

    let dataToUpdate = {
      name: values.name,
      description: values.description,
      status: status ? "active" : "inactive",
    };

    console.log(dataToUpdate);

    props.updateMyCampaign({
      campaignId: campaign._id,
      data: dataToUpdate,
    });
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value: string) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  console.log("----campaignStatus");
  console.log(campaignStatus);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        name: name,
        platform: platform,
        description: description,
        budget: budget,
      }}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Campaign Name"
        value={name}
        rules={[
          {
            required: true,
            message: "Please input Campaign name!",
            whitespace: true,
          },
        ]}
      >
        <Input autoComplete="off" />
      </Form.Item>

      <Form.Item name="platform" label="Campaign Platform">
        <Select disabled>
          <Select.Option value="instagram">Instagram</Select.Option>
          <Select.Option value="twitter">Twitter</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="budget" label="Budget">
        <Input
          disabled
          autoComplete="off"
          type="number"
          prefix="₹"
          suffix="INR"
        />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[
          {
            required: true,
            message: "Please add description!",
            whitespace: true,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        name="status"
        label="Status"
        extra="If Active - Will list in
        public campaign list"
      >
        <Switch defaultChecked={campaignStatus} />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Update Campaign
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormEditCampaign;
