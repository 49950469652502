import React from "react";
import { Row, Col, Layout } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import AdminUsersList from "./Admin/AdminUsersList";
import AdminLinksList from "./Admin/AdminLinksList";

import MenuAdmin from "../components/admin/MenuAdmin";
import Admin from "../components/admin";

import AdminSidebarMenu from "../components/admin/AdminSidebarMenu";
// import MenuAdmin from "../components/admin/MenuAdmin";

const { Header, Content, Footer } = Layout;

function MasterAdmin() {
  let { path, url } = useRouteMatch();

  console.log(path);
  console.log(url);

  console.log();
  return (
    <Layout id="admin-page">
      <HeaderMain />

      <Content className="site-layout">
        {/* <MenuAdmin /> */}

        <Router>
          <Row>
            {/* <Col md={3}>
              <AdminSidebarMenu />
            </Col> */}
            <Col md={24}>
              <MenuAdmin />
              <br />
              <Switch>
                <Route
                  exact
                  path="/masteradmin"
                  component={Admin}
                  // render={(props) => <Admin {...props} />}
                />
                <Route
                  exact
                  path="/masteradmin/users"
                  component={AdminUsersList}
                  // render={(props) => <AdminUsersList {...props} />}
                />
                <Route
                  exact
                  path="/masteradmin/links"
                  component={AdminLinksList}
                  // render={(props) => <AdminUsersList {...props} />}
                />
              </Switch>
            </Col>
          </Row>
        </Router>
        <FooterMain />
      </Content>
    </Layout>
  );
}

export default MasterAdmin;
