import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Layout, Tabs, Button } from "antd";
import { ConsoleSqlOutlined, InstagramOutlined } from "@ant-design/icons";

import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import AuthInstagram from "../components/influencers/AuthInstagram";
import PublicListInfluencersInstagram from "../components/publicListInfluencers/PublicListInfluencersInstagram";

import * as actions from "../redux/actions";
import { twitterAuthRequest } from "../redux/influencers/action";

const { Content } = Layout;
const { TabPane } = Tabs;

function AuthYoutubeCallBack(props) {
  const { publicInfluencerPages, loggedUserData, saveYoutubeAccounts } = props;

  const instagramPages =
    (publicInfluencerPages.data && publicInfluencerPages.data.instagramPages) ||
    [];

  const [hasInstagramPages, setHasInstagramPages] = useState(false);
  const [activeTab, setActiveTab] = useState("tab-instagram");
  useEffect(() => {
    const { history } = props;
    const { location } = history;
    const { search } = location;
    console.log(search);
    // console.log(match);
    if (search.indexOf("code=") !== -1) {
      const urlParams = new URLSearchParams(search);
      let code = urlParams.get("code");
      if (code !== "") {
        console.log(code);
        console.log(code);
        props.saveYoutubeAccountsRequest({
          code: code,
        });
      }
    }

    // props.publicInfluencerPagesRequest();
  }, []);

  //   useEffect(() => {
  //     if (publicInfluencerPages && publicInfluencerPages.isLoading === false) {
  //       if (instagramPages.length > 0) {
  //         setHasInstagramPages(true);
  //         setActiveTab("tab-instagram");
  //       }
  //     }
  //   }, [instagramPages]);

  useEffect(() => {
    if (saveYoutubeAccounts && saveYoutubeAccounts.isSuccess) {
      props.history.push("/influencers");
    }
  }, [saveYoutubeAccounts]);

  return (
    <Layout id="page-public-influencers">
      <HeaderMain />
      <Content className="site-layout"></Content>
      <FooterMain />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  login: state.auth.login,
  loggedUserData: state.auth.login.data || false,
  // instagramPages: state.influencers.myInfluencerPages.data.instagramPages || [],
  publicInfluencerPages: state.influencers.publicInfluencerPages || false,
  saveYoutubeAccounts: state.influencers.saveYoutubeAccounts || false,
});

const mapDispatchToProps = (dispatch) => ({
  publicInfluencerPagesRequest: (data) =>
    dispatch(actions.publicInfluencerPagesRequest(data)),
  twitterAuthVerifyRequest: (data) =>
    dispatch(actions.twitterAuthVerifyRequest(data)),
  saveYoutubeAccountsRequest: (data) =>
    dispatch(actions.saveYoutubeAccountsRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthYoutubeCallBack)
);
