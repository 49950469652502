import React from "react";
import { withRouter, Link } from "react-router-dom";

import { Row, Col, Layout } from "antd";
import Auth from "./auth";
import Logo from "../images/logo.png";
import LogoA from "../images/logoA.png";
const { Header } = Layout;

class HeaderMain extends React.Component {
  render() {
    return (
      <Header id="header-main" style={{}}>
        <Row className="fix-header-row">
          <Col xl={8} md={8} xs={12} className="logo-block">
            <Link to="/">
              <img className="logo" src={LogoA} />
            </Link>
          </Col>
          <Col
            className="auth-block"
            xl={16}
            md={16}
            xs={12}
            // style={{ textAlign: "right", paddingRight: "20px" }}
          >
            <Auth />
          </Col>
        </Row>
      </Header>
    );
  }
}

export default withRouter(HeaderMain);
