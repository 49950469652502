import React from "react";
import { Input, Button, Space, Popover, Form, Row, Col } from "antd";
import { TwitterOutlined } from "@ant-design/icons";

function TwitterPages(props) {
  const { pages } = props;

  // const[perPost]
  const doPriceUpdate = (rowId, values) => {
    let toUpdate = {
      costPerPost: values.costPerPost,
    };
    props.doUpdateInfluencerTwitter({ rowId, data: toUpdate });
  };

  const openInstagramPage = (username) => {
    let link = "https://twitter.com/" + username;
    window.open(link);
  };

  const openLinkNewPage = (link) => {
    window.open(link);
  };

  const EditPrice = (data) => {
    const { page, username } = data;
    const rowId = page._id;
    const costPerPost = page.costPerPost || 0;
    let title = page.username + " - Edit Price";
    return (
      <Popover
        content={() => (
          <Form
            // {...layout}
            name="basic"
            initialValues={{ costPerPost: costPerPost }}
            onFinish={(values) => doPriceUpdate(rowId, values)}
            // onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Cost Per Post"
              name="costPerPost"
              rules={[
                { required: true, message: "Please input your cost per post!" },
              ]}
            >
              <Input autoComplete="off" type="number" prefix="₹" suffix="INR" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        )}
        title={title}
        trigger="click"
        // visible={this.state.visible}
        // onVisibleChange={this.handleVisibleChange}
      >
        <Button className="button-edit-price" type="link">
          Edit Cost
        </Button>
      </Popover>
    );
  };

  const renderPageDetails = (page) => {
    const {
      biography,
      followers_count,
      follows_count,
      media_count,
      name,
      website,
      profile_picture_url,
      username,
      costPerPost,
    } = page;
    let instagramPagesPageUrl = "https://www.instagram.com/" + username;
    let bioWebsite = "";
    if (website !== "") {
      bioWebsite = (
        <Button
          className="bio-page-link"
          type="link"
          onClick={() => openLinkNewPage(website)}
        >
          {website}
        </Button>
      );
    }
    let costPerPostFinal = costPerPost ? "₹" + costPerPost : "";
    return (
      <Row id="instagram-page-view-wrapper">
        <Col xs={24} md={3} className="details-wrap">
          <img alt="logo" className="profile-image" src={profile_picture_url} />
          <div className="user-name">
            <TwitterOutlined className="instagram-icon" />
            {username}
          </div>
        </Col>
        <Col xs={24} md={5} className="details-bio-wrap">
          <div className="user-fullname">{name}</div>
          <div className="user-bio">{biography}</div>
          <div className="user-website">{bioWebsite}</div>
        </Col>
        <Col xs={12} md={3} className="posts-wrap">
          <div className="span-label">Posts</div>
          <div className="span-count">{media_count}</div>
        </Col>
        <Col xs={12} md={3} className="followers-wrap">
          <div className="span-label">Followers</div>
          <div className="span-count">{followers_count}</div>
        </Col>
        <Col xs={12} md={3} className="following-wrap">
          <div className="span-label">Following</div>
          <div className="span-count">{follows_count}</div>
        </Col>
        <Col xs={12} md={7} className="cost-wrap">
          <div className="span-label">Cost Per Post</div>
          <div className="span-signin">{costPerPostFinal}</div>
          <EditPrice page={page} />
        </Col>
        {/* <Col xs={24} md={4} className="contact-wrap">
          <Button
            className="instagram-page-link"
            type="link"
            onClick={() => openInstagramPage(username)}
          >
            Contact {username}
          </Button>
        </Col> */}
      </Row>
    );
  };

  return (
    <div className="my-instagram-pages">
      {pages.map((page) => {
        return renderPageDetails(page);
      })}
    </div>
  );
}

export default TwitterPages;
