import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Button, Row, Col } from "antd";
import SideDrawer from "../SideDrawer";
import FormAddNewShortShout from "../forms/FormAddNewShortShout";
import * as actions from "../../redux/actions";
import MyStats from "./MyStats";
import ManageLink from "./ManageLink";
import ShortShout from "../shortshout";
import FormEmailVerification from "../forms/FormEmailVerification";
import FormAddUserName from "../forms/FormAddUserName";
import FormUploadAvtar from "../forms/FormUploadAvtar";

import { get_tempVariableToHold_isEmailVerified } from "../../services";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewShortShoutForm: false,
      currentFormTitle: "",
      editLinkData: false,
      addEditLinkFormButtonText: "",
      showGraphIndex: [],
    };
  }

  _toggleDisplayGraph = (id) => {
    let existing = [...this.state.showGraphIndex];
    let newData = [];
    if (existing.indexOf(id) === -1) {
      existing.push(id);
      newData = existing;
    } else {
      existing.forEach((i) => {
        if (i !== id) {
          newData.push(i);
        }
      });
    }

    this.setState({
      showGraphIndex: newData,
    });
  };

  componentDidMount() {
    if (this.props.loggedUserData) {
      const { username } = this.props.loggedUserData;
      if (username && username !== "") {
        this.props.myAvatarRequest();
        this.props.myLinksRequest();
        this.props.myStatsRequest();
      }
    }
  }

  _openNewShortShout = () => {
    this.setState({
      openNewShortShoutForm: true,
      currentFormTitle: "Add New Link",
      editLinkData: false,
      addEditLinkFormButtonText: "Add New Link",
    });
  };
  _closeSideDrawer = () => {
    this.setState({
      openNewShortShoutForm: false,
      editLinkData: false,
    });
  };

  _addNewLink = (data) => {
    this.props.newLinkRequest(data);
    this._closeSideDrawer();
  };

  _updateLink = (data) => {
    this.props.updateLinkRequest({
      _id: data.linkData._id,
      data: data.values,
    });
    this._closeSideDrawer();
  };

  _onDeleteLink = (data) => {
    this.props.deleteLinkRequest(data);
  };

  _onEditLink = (data) => {
    this.setState({
      openNewShortShoutForm: true,
      currentFormTitle: "Edit Link",
      editLinkData: data,
      addEditLinkFormButtonText: "Update Link",
    });
  };

  _doLinkStatusChange = (data) => {
    this.props.updateLinkStatusRequest({
      _id: data.linkData._id,
      status: data.newStatus === true ? "active" : "inactive",
    });
  };

  render() {
    let { myLinks } = this.props;
    let { loggedUserData } = this.props;
    let myShortShoutUrl =
      process.env.REACT_APP_WEB_BASE_URL + "/" + loggedUserData.username;
    let linksToShow = myLinks.filter((link) => link.status === "active");

    let isUsernameAdded =
      loggedUserData &&
      loggedUserData.username &&
      loggedUserData.username !== ""
        ? true
        : false;

    let isEmailVerified =
      (loggedUserData && loggedUserData.isEmailVerified) || false;
    if (isEmailVerified === false) {
      isEmailVerified = get_tempVariableToHold_isEmailVerified();
    }

    let avatarBase64 = "";
    const { myAvatar } = this.props;
    if (
      myAvatar &&
      myAvatar.data &&
      myAvatar.data.avatarBase64 &&
      myAvatar.data.avatarBase64 !== ""
    ) {
      avatarBase64 = myAvatar.data.avatarBase64;
    }

    return (
      <>
        {isEmailVerified ? null : <FormEmailVerification />}
        {!isUsernameAdded ? (
          <FormAddUserName />
        ) : (
          <Row>
            <Col className="managelinks-block" xl={14} md={14} xs={24}>
              <MyStats data={this.props.myStats} />
              {/* <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  fontWeight: 700,
                  padding: 10,
                  background: "yellow",
                  boxShadow: "rgb(17 17 17) 0px 15px 10px -15px",
                }}
              >
                ALERT!!
                <br />
                We are now live on playstore. <br />
                Check your links stats on mobile application.
                <br />
                <a
                  href="https://play.google.com/store/apps/details?id=com.shortshout.shortshout"
                  target="_BLANK"
                  title="Android App on play store"
                >
                  Click Here To Download Android App!!
                </a>
              </div> */}
              <br />
              <Button
                type="primary"
                onClick={this._openNewShortShout}
                className="add-new-link-button"
              >
                Add New Link
              </Button>
              <br /> <br />
              {myLinks.map((link, i) => {
                return (
                  <ManageLink
                    key={i}
                    data={link}
                    doDeleteLink={this._onDeleteLink}
                    doLinkStatusChange={this._doLinkStatusChange}
                    onEditLink={this._onEditLink}
                    showGraphIndex={this.state.showGraphIndex}
                    toggleDisplayGraph={this._toggleDisplayGraph}
                  />
                );
              })}
              <SideDrawer
                placement="left"
                title={this.state.currentFormTitle}
                open={this.state.openNewShortShoutForm}
                onSideDrawerClose={this._closeSideDrawer}
              >
                {this.state.openNewShortShoutForm ? (
                  <FormAddNewShortShout
                    addEditLinkFormButtonText={
                      this.state.addEditLinkFormButtonText
                    }
                    newLink={this.props.newLink}
                    updateLink={this.props.updateLink}
                    addNewLink={this._addNewLink}
                    editLinkData={this.state.editLinkData}
                    doUpdateLink={this._updateLink}
                  />
                ) : null}
              </SideDrawer>
            </Col>
            <Col xl={10} md={10} xs={24} className="dashboard-sample-view">
              <b>My Short Shout URL: </b>
              <a
                href={myShortShoutUrl}
                target="_BLANK"
                rel="noopener noreferrer"
                style={{
                  color: "#1890FF",
                  fontWeight: "bold",
                  // fontStyle: "italic",
                  fontSize: "20px",
                }}
              >
                {myShortShoutUrl}
              </a>
              <br />
              <FormUploadAvtar />
              <br />
              <div className="sample-short-shout-box">
                <ShortShout
                  username={loggedUserData.username}
                  avatar={avatarBase64}
                  links={linksToShow}
                />
              </div>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  newLink: state.links.newLink || false,
  loggedUserData: state.auth.login.data || false,
  links: state.links,
  myLinks: state.links.myLinks.data || [],
  myStats: state.dashboard.myStats,
  updateLink: state.links.updateLink || false,
  myAvatar: state.auth.myAvatar || false,
});

const mapDispatchToProps = (dispatch) => ({
  newLinkRequest: (data) => dispatch(actions.newLinkRequest(data)),
  updateLinkRequest: (data) => dispatch(actions.updateLinkRequest(data)),
  myLinksRequest: (data) => dispatch(actions.myLinksRequest(data)),
  deleteLinkRequest: (data) => dispatch(actions.deleteLinkRequest(data)),
  updateLinkStatusRequest: (data) =>
    dispatch(actions.updateLinkStatusRequest(data)),
  myStatsRequest: (data) => dispatch(actions.myStatsRequest(data)),
  myAvatarRequest: (data) => dispatch(actions.myAvatarRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
