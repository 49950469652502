import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { Form, Input, Select, Button, Spin } from "antd";

import * as actions from "../../redux/actions";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function FormResendVerificationLink(props) {
  const doResendVerificationLink = (data) => {
    props.sendEmailVerifyCodeRequest(data);
  };
  let { isLoading } = props.register;
  return (
    <Form
      {...formItemLayout}
      // form={form}
      className="form-resendVerificationLink"
      name="resendVerificationLink"
      onFinish={doResendVerificationLink}
      scrollToFirstError
    >
      <h1 className="form-heading">Re-send verification link</h1>

      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        {isLoading ? (
          <Spin />
        ) : (
          <>
            <Button
              type="primary"
              className="button-register"
              htmlType="submit"
            >
              Re-send verification link
            </Button>
            <br />
            <br />
            <Link to="/login">Login!!</Link>
          </>
        )}
      </Form.Item>
    </Form>
  );
}

const mapStateToProps = (state) => ({
  login: state.auth.login,
  register: state.auth.register,
  resetPassword: state.auth.resetPassword,
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  registerRequest: (data) => dispatch(actions.registerRequest(data)),
  loginRequest: (data) => dispatch(actions.loginRequest(data)),
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  logoutRequest: (data) => dispatch(actions.logoutRequest(data)),
  resetPasswordRequest: (data) => dispatch(actions.resetPasswordRequest(data)),
  sendEmailVerifyCodeRequest: (data) =>
    dispatch(actions.sendEmailVerifyCodeRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormResendVerificationLink)
);
