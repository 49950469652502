import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  publicShortShout: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  recentlyAddedUsers: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
};

/* public short shout */
const getPublicShortShoutRequest = (state, action) =>
  update(state, {
    publicShortShout: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const getPublicShortShoutSuccess = (state, action) =>
  update(state, {
    publicShortShout: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const getPublicShortShoutError = (state, action) =>
  update(state, {
    publicShortShout: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });
/* public short shout */

/* recently added users */
const getRecentlyAddedUsersRequest = (state, action) =>
  update(state, {
    recentlyAddedUsers: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const getRecentlyAddedUsersSuccess = (state, action) =>
  update(state, {
    recentlyAddedUsers: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const getRecentlyAddedUsersError = (state, action) =>
  update(state, {
    recentlyAddedUsers: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });
/* recently added users */

export default handleActions(
  {
    [constants.GET_PUBLIC_SHORT_SHOUT_REQUEST]: getPublicShortShoutRequest,
    [constants.GET_PUBLIC_SHORT_SHOUT_SUCCESS]: getPublicShortShoutSuccess,
    [constants.GET_PUBLIC_SHORT_SHOUT_ERROR]: getPublicShortShoutError,

    [constants.GET_RECENTLY_ADDED_USERS_REQUEST]: getRecentlyAddedUsersRequest,
    [constants.GET_RECENTLY_ADDED_USERS_SUCCESS]: getRecentlyAddedUsersSuccess,
    [constants.GET_RECENTLY_ADDED_USERS_ERROR]: getRecentlyAddedUsersError,
  },
  initialState
);
