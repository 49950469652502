import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Form, Input, Spin, Popconfirm } from "antd";

import * as actions from "../../redux/actions";

class FormAddUserName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openVerifyEmailForm: false,
      usernameText: "",
    };
    this.refFormAddUsername = React.createRef();
  }

  onAddUserName = (values) => {
    this.props.addUsernameRequest(values);
  };

  onUsernameChange = (e) => {
    this.setState({
      usernameText: e.target.value,
    });
  };

  render() {
    return (
      <Row className="add-username-block">
        <Col xl={24}>
          <span className="span-text">Choose your ShortShout username.</span>
          <br />
          <br />
          <Form
            ref={this.refFormAddUsername}
            className="verify-form"
            onFinish={this.onAddUserName}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <b>https://shortshout.in/{this.state.usernameText}</b>
            </div>

            <br />
            <Form.Item
              name="username"
              style={{ fontSize: "20px" }}
              rules={[
                {
                  required: true,
                  message: "Please input your username.",
                },
                {
                  min: 3,
                  message: "Username cannot be less than 3 characters.",
                },
                {
                  max: 30,
                  message: "Username cannot exceed 30 characters.",
                },
                {
                  whitespace: true,
                  message: "Space cannot be entered.",
                },
                {
                  message: "Only alphabets and numbers can be entered.",
                  pattern: /^[A-Za-z0-9]+$/,
                },
              ]}
            >
              <Input
                onChange={this.onUsernameChange}
                autoComplete="off"
                addonBefore="https://shortshout.in/"
              />
            </Form.Item>
            <Form.Item>
              {this.props.addUsername.isLoading ? (
                <Spin />
              ) : (
                <>
                  <Popconfirm
                    title="Are you sure you want to create this username?"
                    onConfirm={() => {
                      this.refFormAddUsername.current.submit();
                    }}
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                  >
                    <Button type="primary" className="login-form-button">
                      Create Username
                    </Button>
                  </Popconfirm>
                </>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  addUsername: state.auth.addUsername,
});

const mapDispatchToProps = (dispatch) => ({
  addUsernameRequest: (data) => dispatch(actions.addUsernameRequest(data)),
  //   sendEmailVerifyCodeRequest: (data) =>
  //     dispatch(actions.sendEmailVerifyCodeRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormAddUserName);
