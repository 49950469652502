import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import AuthGoogle from "./AuthGoogle";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showResetPassword: false,
    };
  }
  onFinish = (values) => {
    this.props.doLogin(values);
  };
  onFinishForgotPassword = (values) => {
    this.props.doResetPassword(values);
  };
  render() {
    let { isLoading } = this.props.login;
    let { showResetPassword } = this.state;
    let isResetPasswordLoading = this.props.resetPassword.isLoading;
    return (
      <>
        {!showResetPassword ? (
          <Form
            name="normal_login"
            className="form-login"
            initialValues={{
              remember: true,
            }}
            onFinish={this.onFinish}
          >
            <h1 className="form-heading">Login</h1>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item className="label-forgot-password">
              <a
                className="login-form-forgot"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    showResetPassword: true,
                  });
                }}
              >
                Forgot password?
              </a>
              <br />
              <Link to="/resendVerificationLink">
                Re-send verification link
              </Link>
              <br />
              <br />
            </Form.Item>
            <Form.Item>
              {isLoading ? (
                <Spin />
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button-login"
                >
                  Log in
                </Button>
              )}
              {/* Or <a href="">register now!</a> */}
            </Form.Item>
            <Form.Item className="or-separator">Or</Form.Item>
            <AuthGoogle />
            <Form.Item className="label-new-account">
              <Link to="/register">Create New Account!</Link>
            </Form.Item>
          </Form>
        ) : (
          <Form
            name="forgotpassword"
            className="form-reset-password"
            initialValues={{
              remember: true,
            }}
            onFinish={this.onFinishForgotPassword}
          >
            <h1 className="form-heading">Reset Password</h1>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Enter your registered email"
              />
            </Form.Item>

            <Form.Item>
              {isResetPasswordLoading ? (
                <Spin />
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button-reset-password"
                >
                  Reset Password
                </Button>
              )}
            </Form.Item>
            <Form.Item className="label-login">
              <a
                className="login-form-forgot"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    showResetPassword: false,
                  });
                }}
              >
                Click here to login
              </a>
            </Form.Item>
          </Form>
        )}
      </>
    );
  }
}

export default LoginForm;
