import React, { useEffect, useState } from "react";
import { Layout, Table, Input, Row, Col, Modal, Button } from "antd";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";

// import PageTitle from "../components/PageTitle";
// import HeaderMain from "../components/HeaderMain";

import * as actions from "../../redux/actions";
import { getUserShortShoutUrl } from "../../services";

const { Content } = Layout;

function AdminUsersList(props) {
  const { loggedUserData, defaultOrganization, organizationInvoicesList } =
    props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [searchText, setSearchText] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [tableDataSourceBackup, setTableDataSourceBackup] = useState([]);

  useEffect(() => {
    props.adminGetLatestUsersRequest();
    // if (loggedUserData.uid && defaultOrganization.organizationId) {
    //   props.getOrganizationInvoicesRequest({
    //     organizationId: defaultOrganization.organizationId,
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserData]);

  useEffect(() => {
    setUsersList(props.usersList);
  }, [props.usersList]);

  useEffect(() => {
    const tableData = [];
    usersList &&
      usersList.forEach((user, i) => {
        const {
          _id,
          username,
          email,
          name,
          status,
          role,
          createdAt,
          isEmailVerified,
          updatedAt,
          user_links,
          user_stats,
          user_links_deleted,
          isSendDoVerificationEmailRequest,
        } = user;

        let shortshoutUrl = getUserShortShoutUrl(user);

        let statusIsEmailVerified = isEmailVerified;

        let joinedOn = moment(createdAt).format("lll");
        let updatedOn = moment(updatedAt).format("lll");

        let countPageImpressions =
          (user_stats && user_stats.pageViewsCount) || 0;
        let countLinkClicks = (user_stats && user_stats.linksClickedCount) || 0;
        let countTotalLinks = user_links.length || 0;

        user = {
          ...user,
          shortshoutUrl: shortshoutUrl,
          serialnumber: i + 1,
          statusIsEmailVerified: statusIsEmailVerified,
          joinedOn: joinedOn,
          updatedOn: updatedOn,
          countPageImpressions: countPageImpressions,
          countLinkClicks: countLinkClicks,
          countTotalLinks: countTotalLinks,
          isSendDoVerificationEmailRequest: isSendDoVerificationEmailRequest,
        };

        tableData.push(user);
      });

    if (tableData.length > 0) {
      setTableDataSource(tableData);
      setTableDataSourceBackup(tableData);
    }
  }, [usersList]);

  //   const showErrorsModal = (row) => {
  //     const { failureReasons, originalRow } = row;
  //     const { file } = originalRow;
  //     let modalContent = failureReasons.map((f) => {
  //       return <div>{f}</div>;
  //     });
  //     setModalContent({
  //       title: file.name || "",
  //       content: modalContent,
  //     });
  //     setIsModalVisible(true);
  //   };

  const columns = [
    {
      title: "#",
      dataIndex: "serialnumber",
      key: "serialnumber",
      width: 20,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 40,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 50,
      render: (text, row) => {
        const { shortshoutUrl } = row;

        return (
          <a href={shortshoutUrl} target="_BLANK">
            {text}
          </a>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 80,
    },
    {
      title: "Email Verified",
      dataIndex: "statusIsEmailVerified",
      key: "statusIsEmailVerified",
      width: 100,
      render: (text, row) => {
        const { statusIsEmailVerified, isSendDoVerificationEmailRequest, _id } =
          row;
        return (
          <>
            {statusIsEmailVerified ? "Y" : "N"}
            <br />
            {isSendDoVerificationEmailRequest
              ? "Last Sent:  " +
                moment.unix(isSendDoVerificationEmailRequest).format("lll")
              : null}
            <div>
              {statusIsEmailVerified ? null : (
                <Button
                  size="small"
                  type="link"
                  onClick={() =>
                    props.adminSendDoVerificationEmailRequest({
                      _id: _id,
                    })
                  }
                >
                  Send Do Verification Email
                </Button>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 60,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 30,
    },
    {
      title: "Joined On",
      dataIndex: "joinedOn",
      key: "joinedOn",
      width: 40,
    },
    {
      title: "Updated On",
      dataIndex: "updatedOn",
      key: "updatedOn",
      width: 40,
    },
    {
      title: "Page Impressions",
      dataIndex: "countPageImpressions",
      key: "countPageImpressions",
      width: 60,
      sorter: {
        compare: (a, b) => {
          let timeA = a.countPageImpressions;
          let timeB = b.countPageImpressions;
          return timeA - timeB;
        },
      },
    },
    {
      title: "Link Clicks",
      dataIndex: "countLinkClicks",
      key: "countLinkClicks",
      width: 60,
      sorter: {
        compare: (a, b) => {
          let timeA = a.countLinkClicks;
          let timeB = b.countLinkClicks;
          return timeA - timeB;
        },
      },
    },
    {
      title: "Total Links",
      dataIndex: "countTotalLinks",
      key: "countTotalLinks",
      width: 60,
      sorter: {
        compare: (a, b) => {
          let timeA = a.countTotalLinks;
          let timeB = b.countTotalLinks;
          return timeA - timeB;
        },
      },
    },
  ];

  console.log(tableDataSource);

  return (
    <div className="table-content">
      <Table
        id="table-invoices"
        // title={() => renderHeader()}
        rowClassName={(record, index) =>
          record.isEmailVerified ? "email-verified" : "email-not-verified"
        }
        showHeader={true}
        tableLayout="fixed"
        pagination={{
          pageSize: 200,
          size: "small",
          showSizeChanger: false,
        }}
        size="small"
        bordered={true}
        columns={columns}
        dataSource={tableDataSource}
        scroll={{ x: 1500 }}
      />

      <Modal
        closable={false}
        title={modalContent.title || ""}
        visible={isModalVisible}
        cancelText={() => null}
        onOk={() => setIsModalVisible(false)}
        // onCancel={handleCancel}
      >
        {modalContent.content || ""}
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  // newLink: state.links.newLink || false,
  loggedUserData: state.auth.login.data || false,
  usersList: state.admin.usersList.data || [],
  admin: state.admin,
  // myLinks: state.links.myLinks.data || [],
  // myStats: state.dashboard.myStats,
  // updateLink: state.links.updateLink || false,
});

const mapDispatchToProps = (dispatch) => ({
  adminGetLatestUsersRequest: (data) =>
    dispatch(actions.adminGetLatestUsersRequest(data)),
  adminGetLatestLinksRequest: (data) =>
    dispatch(actions.adminGetLatestLinksRequest(data)),
  adminGetCompleteStatsRequest: (data) =>
    dispatch(actions.adminGetCompleteStatsRequest(data)),
  adminSendDoVerificationEmailRequest: (data) =>
    dispatch(actions.adminSendDoVerificationEmailRequest(data)),
  // updateLinkRequest: (data) => dispatch(actions.updateLinkRequest(data)),
  // myLinksRequest: (data) => dispatch(actions.myLinksRequest(data)),
  // deleteLinkRequest: (data) => dispatch(actions.deleteLinkRequest(data)),
  // updateLinkStatusRequest: (data) =>
  //   dispatch(actions.updateLinkStatusRequest(data)),
  // myStatsRequest: (data) => dispatch(actions.myStatsRequest(data)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminUsersList)
);
