import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Layout, Button } from "antd";

import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";
import PageTitle from "../components/PageTitle";

import MenuInfluencers from "../components/MenuInfluencers";

import DashboardInfluencers from "../components/influencers/DashboardInfluencers";

import * as actions from "../redux/actions";

const { Content } = Layout;

function Influencers(props) {
  const { myInfluencerPages } = props;
  const instagramPages =
    (myInfluencerPages.data && myInfluencerPages.data.instagramPages) || [];
  const twitterPages =
    (myInfluencerPages.data && myInfluencerPages.data.twitterPages) || [];
  const youtubePages =
    (myInfluencerPages.data && myInfluencerPages.data.youtubePages) || [];

  useEffect(() => {
    props.myInfluencerPagesRequest();
  }, []);

  const doUpdateInfluencerInstagram = (data) => {
    props.updateInfluencerInstagramRequest(data);
  };

  const doUpdateInfluencerTwitter = (data) => {
    props.updateInfluencerTwitterRequest(data);
  };

  const doUpdateInfluencerYoutube = (data) => {
    props.updateInfluencerYoutubeRequest(data);
  };

  let showConnectProfileButton = false;
  if (
    instagramPages.length === 0 &&
    twitterPages.length === 0 &&
    youtubePages.length === 0
  ) {
    showConnectProfileButton = true;
  }

  return (
    <Layout id="page-influencers">
      <HeaderMain />
      <Content className="site-layout">
        <MenuInfluencers />
        <PageTitle title="My Influencer Pages" />
        <br />
        <Link to="/influencers/dashboardCampaigns">
          Looking for Influencers? Click to create a campaign.
        </Link>
        <br />
        <br />
        {showConnectProfileButton ? (
          <Link to="/influencers/socialProfileAuth">
            <Button type="primary">
              Click here to connect social profiles
            </Button>
          </Link>
        ) : (
          <DashboardInfluencers
            instagramPages={instagramPages}
            twitterPages={twitterPages}
            youtubePages={youtubePages}
            doUpdateInfluencerInstagram={doUpdateInfluencerInstagram}
            doUpdateInfluencerTwitter={doUpdateInfluencerTwitter}
            doUpdateInfluencerYoutube={doUpdateInfluencerYoutube}
          />
        )}
      </Content>
      <FooterMain />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  login: state.auth.login,
  // instagramPages: state.influencers.myInfluencerPages.data.instagramPages || [],
  myInfluencerPages: state.influencers.myInfluencerPages || false,
});

const mapDispatchToProps = (dispatch) => ({
  myInfluencerPagesRequest: (data) =>
    dispatch(actions.myInfluencerPagesRequest(data)),
  updateInfluencerInstagramRequest: (data) =>
    dispatch(actions.updateInfluencerInstagramRequest(data)),
  updateInfluencerTwitterRequest: (data) =>
    dispatch(actions.updateInfluencerTwitterRequest(data)),
  updateInfluencerYoutubeRequest: (data) =>
    dispatch(actions.updateInfluencerYoutubeRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Influencers)
);
