import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Menu } from "antd";

function MenuInfluencers() {
  return (
    <div id="menu-influencers">
      <Menu
        // onClick={handleClick}
        // selectedKeys={[current]}
        mode="horizontal"
      >
        <Menu.Item key="influencers">
          <Link to="/influencers">My Influencer Pages</Link>
        </Menu.Item>
        <Menu.Item key="influencers/socialProfileAuth">
          <Link to="/influencers/socialProfileAuth">
            Connect Social Profile
          </Link>
        </Menu.Item>
        <Menu.Item key="/influencers/dashboardCampaigns">
          <Link to="/influencers/dashboardCampaigns"> My Campaigns</Link>
        </Menu.Item>
        <Menu.Item key="/activeCampaigns">
          <Link to="/activeCampaigns"> Active Campaigns</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default MenuInfluencers;
