import React from "react";
import { Row, Col } from "antd";

function CampaignDetails(props) {
  const { campaign } = props;
  const { status, platform, name, description, budget } = campaign;
  let budgetToShow = budget ? "₹" + budget : "";
  return (
    <Row id="campaign-details">
      <Col xs={12} md={4} className="details-wrap">
        <div className="label">Status</div>
        <div className="value">{status}</div>
      </Col>
      <Col xs={12} md={4} className="details-wrap">
        <div className="label">Platform</div>
        <div className="value">{platform}</div>
      </Col>
      <Col xs={12} md={6} className="details-wrap">
        <div className="label">Campaign Name</div>
        <div className="value">{name}</div>
      </Col>
      <Col xs={12} md={4} className="details-wrap">
        <div className="label">Budget</div>
        <div className="value">{budgetToShow}</div>
      </Col>
      <Col xs={24} md={6} className="details-wrap">
        <div className="label">Description</div>
        <div className="value">{description}</div>
      </Col>
    </Row>
  );
}

export default CampaignDetails;
