import { combineReducers } from "redux";
import generic from "./generic/reducer";
import auth from "./auth/reducer";
import links from "./links/reducer";
import shortshout from "./shortshout/reducer";
import dashboard from "./dashboard/reducer";
import admin from "./admin/reducer";
import influencers from "./influencers/reducer";

const appReducer = combineReducers({
  generic,
  auth,
  links,
  shortshout,
  dashboard,
  admin,
  influencers,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
