import React from "react";
import { Link } from "react-router-dom";

import { Input, Button, Space, Popover, Form, Row, Col } from "antd";
import { TwitterOutlined, YoutubeOutlined } from "@ant-design/icons";
// import { Campaigns } from "../../../../shortshout-server/models";

import { getLoggedUser } from "../../services";

function CampaignsList(props) {
  const { campaigns } = props;

  // const[perPost]
  const doPriceUpdate = (rowId, values) => {
    let toUpdate = {
      costPerPost: values.costPerPost,
    };
    props.doUpdateInfluencerTwitter({ rowId, data: toUpdate });
  };

  const openInstagramPage = (username) => {
    let link = "https://twitter.com/" + username;
    window.open(link);
  };

  const openLinkNewPage = (link) => {
    window.open(link);
  };

  const EditPrice = (data) => {
    const { page, username } = data;
    const rowId = page._id;
    const costPerPost = page.costPerPost || 0;
    let title = page.username + " - Edit Price";

    return (
      <Popover
        content={() => (
          <Form
            // {...layout}
            name="basic"
            initialValues={{ costPerPost: costPerPost }}
            onFinish={(values) => doPriceUpdate(rowId, values)}
            // onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Cost Per Post"
              name="costPerPost"
              rules={[
                { required: true, message: "Please input your cost per post!" },
              ]}
            >
              <Input autoComplete="off" type="number" prefix="₹" suffix="INR" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        )}
        title={title}
        trigger="click"
        // visible={this.state.visible}
        // onVisibleChange={this.handleVisibleChange}
      >
        <Button className="button-edit-price" type="link">
          Edit Cost
        </Button>
      </Popover>
    );
  };

  let loggedUserInfo = getLoggedUser();

  const renderCampaignDetails = (key, campaign) => {
    const { budget, description, name, platform, status } = campaign;
    // let instagramPagesPageUrl = "https://www.instagram.com/" + username;
    // let bioWebsite = "";
    // if (website !== "") {
    //   bioWebsite = (
    //     <Button
    //       className="bio-page-link"
    //       type="link"
    //       onClick={() => openLinkNewPage(website)}
    //     >
    //       {website}
    //     </Button>
    //   );
    // }
    let budgetToShow = budget ? "₹" + budget : "";
    let manageLink = "/influencers/manageCampaign/" + campaign._id;
    let num = key + 1;
    return (
      <Row key={key} className="campaign-row">
        <Col xs={24} md={1} className="details-wrap">
          <div className="value"> {num}</div>
        </Col>
        <Col xs={12} md={4} className="details-wrap">
          <div className="label">Status</div>

          {!loggedUserInfo ? (
            <Link to="login">
              <span>Sign in for details</span>
            </Link>
          ) : (
            <div className="value"> {status}</div>
          )}
        </Col>
        <Col xs={12} md={4} className="details-wrap">
          {/* <div className="label">Platform</div> */}
          <div className="value">
            {platform === "youtube" ? <YoutubeOutlined /> : null}
            {platform === "twitter" ? <TwitterOutlined /> : null}
            <br />
            {platform}
          </div>
        </Col>
        <Col xs={12} md={4} className="details-wrap">
          <div className="label">Campaign Name</div>
          <div className="value"> {name}</div>
        </Col>
        <Col xs={12} md={3} className="details-wrap">
          <div className="label">Budget</div>
          {!loggedUserInfo ? (
            <Link to="login">
              <span>Sign in for details</span>
            </Link>
          ) : (
            <div className="value"> {budgetToShow}</div>
          )}
        </Col>
        <Col xs={24} md={4} className="details-wrap">
          <div className="label">Description</div>
          <div className="value"> {description}</div>
        </Col>
        <Col xs={12} md={4} className="details-wrap">
          {!loggedUserInfo ? (
            <Link to="login">
              <span>Sign in for details</span>
            </Link>
          ) : status === "active" ? (
            <Button type="link" onClick={() => props.applyCampaign(campaign)}>
              Apply Campaign
            </Button>
          ) : null}
        </Col>
        {/* <Col xs={24} md={5} className="details-bio-wrap">
          <div className="user-fullname">{name}</div>
          <div className="user-bio">{biography}</div>
          <div className="user-website">{bioWebsite}</div>
        </Col>
        <Col xs={12} md={3} className="posts-wrap">
          <div className="span-label">Posts</div>
          <div className="span-count">{media_count}</div>
        </Col>
        <Col xs={12} md={3} className="followers-wrap">
          <div className="span-label">Followers</div>
          <div className="span-count">{followers_count}</div>
        </Col>
        <Col xs={12} md={3} className="following-wrap">
          <div className="span-label">Following</div>
          <div className="span-count">{follows_count}</div>
        </Col>
        <Col xs={12} md={7} className="cost-wrap">
          <div className="span-label">Cost Per Post</div>
          <div className="span-signin">{costPerPostFinal}</div>
          <EditPrice page={campaign} />
        </Col> */}
        {/* <Col xs={24} md={4} className="contact-wrap">
          <Button
            className="instagram-page-link"
            type="link"
            onClick={() => openInstagramPage(username)}
          >
            Contact {username}
          </Button>
        </Col> */}
      </Row>
    );
  };

  return (
    <div id="campaigns-list">
      {campaigns && campaigns.length > 0 ? (
        campaigns.map((page, key) => {
          return renderCampaignDetails(key, page);
        })
      ) : (
        <h4>No Active Campaign</h4>
      )}
    </div>
  );
}

export default CampaignsList;
