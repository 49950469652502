import React from "react";
import { Row, Col, Layout } from "antd";
import {
  InstagramOutlined,
  FacebookOutlined,
  AndroidOutlined,
  TwitterOutlined,
  MailOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

const { Footer } = Layout;

class FooterMain extends React.Component {
  render() {
    let ppUrl = process.env.REACT_APP_WEB_BASE_URL + "/privacy-policy";
    return (
      <Footer id="footer-block">
        <a href="mailto:contact@shortshout.in" target="_BLANK" title="Mail">
          <span>contact@shortshout.in</span>
        </a>
        <br />
        <a
          href="https://www.instagram.com/shortshout"
          target="_BLANK"
          title="Instagram"
        >
          <InstagramOutlined className="footer-social-icons" />
        </a>
        <a
          href="https://facebook.com/shortshout"
          target="_BLANK"
          title="Facebook"
        >
          <FacebookOutlined className="footer-social-icons" />
        </a>
        <a
          href="https://twitter.com/shortshout"
          target="_BLANK"
          title="Twitter"
        >
          <TwitterOutlined className="footer-social-icons" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.shortshout.shortshout"
          target="_BLANK"
          title="Android App on play store"
        >
          <AndroidOutlined className="footer-social-icons" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCf8GWw5L4I0S0jtPDR2eadg"
          target="_BLANK"
          title="Youtube"
        >
          <YoutubeOutlined className="footer-social-icons" />
        </a>
        <br />
        <a href={ppUrl} target="_BLANK">
          Privacy
        </a>
        &nbsp; &nbsp; | &nbsp; &nbsp;
        <a href={process.env.REACT_APP_WEB_BASE_URL} target="_BLANK">
          shortshout.in
        </a>
        <br />
      </Footer>
    );
  }
}

export default FooterMain;
