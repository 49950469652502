import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import { GoogleAnalytics } from "./utils/index.js";

const RouteChangeTracker = ({ history }) => {
  const toTrackPage = () => {
    GoogleAnalytics.trackPage();
  };

  useEffect(() => {
    toTrackPage();
  }, []);

  history.listen((location, action) => {
    toTrackPage();
  });

  return <div></div>;
};

export default withRouter(RouteChangeTracker);
