import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showNotification } from "../../services";

export function* newLinkRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/links/addnewlink`,
      action.payload,
      header
    );
    if (response) {
      let { data } = response;
      if (data.error) {
        yield put(actions.newLinkError(data));
        showNotification({
          type: "warning",
          title: data.message,
        });
      } else {
        yield put(actions.newLinkSuccess(response.data));
        showNotification({
          type: "success",
          title: "Link added successfully!!",
        });
        yield put(actions.myLinksRequest());
      }
    }
  } catch (e) {
    yield put(actions.newLinkError(e.response.data));
    showNotification({
      type: "error",
      title: e.response.data.message,
    });
  }
}

export function* myLinksRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/links/getMyLinks`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.myLinksSuccess(response.data.data));
      // toast.success(response.data.message);
    }
  } catch (e) {
    yield put(actions.myLinksError(e.response.data));
  }
}

export function* deleteLinkRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/links/deleteLink`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.deleteLinkSuccess(response.data));
      showNotification({
        type: "success",
        title: "Link deleted successfully!!",
      });
      yield put(actions.myLinksRequest());
    }
  } catch (e) {
    yield put(actions.deleteLinkError(e.response.data));
    showNotification({
      type: "error",
      title: "Delete link failed Failed!!",
      message: e.response.data.message,
    });
  }
}

export function* updateLinkCountRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/links/updateLinkCount`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.updateLinkCountSuccess(response.data));
    }
  } catch (e) {
    yield put(actions.updateLinkCountError(e.response.data));
  }
}

export function* updateLinkStatusRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/links/updateLinkStatus`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.updateLinkStatusSuccess(response.data));
      yield put(actions.myLinksRequest());
    }
  } catch (e) {
    yield put(actions.updateLinkStatusError(e.response.data));
  }
}

export function* updateLinkRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/links/updateLink`,
      action.payload,
      header
    );
    if (response) {
      let { data } = response;
      if (data.error) {
        yield put(actions.updateLinkError(data));
        showNotification({
          type: "warning",
          title: data.message,
        });
      } else {
        yield put(actions.updateLinkSuccess(response.data));
        showNotification({
          type: "success",
          title: "Link updated successfully!!",
        });
        yield put(actions.myLinksRequest());
      }
    }
  } catch (e) {
    yield put(actions.updateLinkError(e.response.data));
    showNotification({
      type: "error",
      title: "Link update Failed!!",
      message: e.response.data.message,
    });
  }
}
