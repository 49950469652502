import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import {
  fireApi,
  showNotification,
  setLoggedInSession,
  getLoggedUser,
  clearLoggedInSession,
  tempVariableToHold_isEmailVerified,
} from "../../services";
// import { notification } from "antd";

export function* registerRequest(action) {
  yield put(actions.showProgressBar(true));
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/register`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.registerSuccess(response.data));
      showNotification({
        type: "success",
        title: "Successfully Registered!! You can login now!!",
      });
    }
  } catch (e) {
    yield put(actions.registerError(e.response.data));
    showNotification({
      type: "error",
      title: "Registration Failed!!",
      message: e.response.data.message,
    });
  }
  yield put(actions.showProgressBar(false));
}

export function* loginRequest(action) {
  yield put(actions.showProgressBar(true));
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/login`,
      action.payload,
      header
    );
    if (response) {
      setLoggedInSession(response.data.data.token);
      let userInfo = getLoggedUser();
      yield put(actions.loginSuccess(userInfo));
      // toast.success(response.data.message);
    }
  } catch (e) {
    yield put(actions.loginError(e.response.data));
    showNotification({
      type: "error",
      title: "Login Failed!!",
      message: e.response.data.message,
    });
  }
  yield put(actions.showProgressBar(false));
}

export function* isLoggedInUser(action) {
  let userInfo = getLoggedUser();
  if (userInfo && userInfo.token) {
    yield put(actions.loginSuccess(userInfo));
  }
  // else {
  //   yield put(actions.logoutSuccess());
  // }
}

export function* logoutRequest(action) {
  yield put(actions.showProgressBar(true));
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/logout`,
      action.payload,
      header
    );
    clearLoggedInSession();
    yield put(actions.logoutRequestSuccess(response));
  } catch (e) {
    clearLoggedInSession();
    yield put(actions.logoutRequestError());
  }
  yield put(actions.showProgressBar(false));
}

export function* resetPasswordRequest(action) {
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/resetPassword`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.resetPasswordSuccess(response.data));
      showNotification({
        type: "success",
        title: "Reset Password Success!!",
        message: response.data.message,
      });
    }
  } catch (e) {
    yield put(actions.resetPasswordError(e.response));
    showNotification({
      type: "error",
      title: "Reset Password Failed!!",
      message: e.response.data.message,
    });
  }
}

export function* verifyEmailRequest(action) {
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/verifyEmail`,
      action.payload,
      header
    );
    if (response) {
      tempVariableToHold_isEmailVerified(true);
      yield put(actions.verifyEmailSuccess(response.data));
      showNotification({
        type: "success",
        title: "Email Verified Successfully!!",
        message: response.data.message,
      });
    }
  } catch (e) {
    yield put(actions.verifyEmailError(e.response));
    showNotification({
      type: "error",
      title: "Email Verified Failed!!",
      message: e.response.data.message,
    });
  }
}

export function* sendEmailVerifyCodeRequest(action) {
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/sendEmailVerifyCode`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.sendEmailVerifyCodeSuccess(response.data));
      showNotification({
        type: "success",
        title: "Verification code sent. Check your email for code!!",
        message: response.data.message,
      });
    }
  } catch (e) {
    yield put(actions.sendEmailVerifyCodeError(e.response));
    showNotification({
      type: "error",
      title: "Failed to sent verification code!!",
      message: e.response.data.message,
    });
  }
}

/* add username after user logged in*/
export function* addUsernameRequest(action) {
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/addUsername`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.addUsernameSuccess(response.data));
      yield put(actions.getRefreshTokenRequest());
      showNotification({
        type: "success",
        title: "Username added successfully!!",
        message: response.data.message,
      });
    }
  } catch (e) {
    yield put(actions.addUsernameError(e.response));
    showNotification({
      type: "error",
      title: "Username add failed!!",
      message: e.response.data.message,
    });
  }
}

/* get refresh token */
export function* getRefreshTokenRequest(action) {
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/getRefreshToken`,
      action.payload,
      header
    );
    if (response) {
      if (response.data && response.data.data && response.data.data.token) {
        setLoggedInSession(response.data.data.token);
        let userInfo = getLoggedUser();
        yield put(actions.loginSuccess(userInfo));
        yield put(actions.getRefreshTokenSuccess(response));
      }
    }
  } catch (e) {
    yield put(actions.getRefreshTokenError(e.response));
  }
}

/* update password request*/
export function* updatePasswordRequest(action) {
  let payload = {
    oldPassword: action.payload.oldPassword || "",
    newPassword: action.payload.newPassword || "",
    confirmPassword: action.payload.confirmPassword || "",
  };
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/updatePassword`,
      payload,
      header
    );
    if (response) {
      yield put(actions.updatePasswordSuccess(response.data));
      showNotification({
        type: "success",
        title: "Password updated successfully!!",
        message: response.data.message,
      });
    }
  } catch (e) {
    yield put(actions.updatePasswordError(e.response.data));
    showNotification({
      type: "error",
      title: "Failed to update password!!",
      message: e.response.data.message,
    });
  }
}

/* add username after user logged in*/
export function* uploadAvatarRequest(action) {
  const header = {
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/uploadAvatar`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.uploadAvatarSuccess(response.data));
      yield put(actions.myAvatarRequest());
      showNotification({
        type: "success",
        title: "Avatar uploaded successfully!!",
        message: response.data.message,
      });
    }
  } catch (e) {
    yield put(actions.uploadAvatarError(e.response));
    showNotification({
      type: "error",
      title: "Avatar upload failed!!",
      message: e.response.data.message,
    });
  }
}

/* my avatar */
export function* myAvatarRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/auth/getMyAvatar`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.myAvatarSuccess(response.data.data));
      // toast.success(response.data.message);
    }
  } catch (e) {
    yield put(actions.myAvatarError(e.response.data));
  }
}
