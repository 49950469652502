import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";

import * as actions from "../../redux/actions";

import { Menu, Dropdown, Avatar, Button } from "antd";
import SideDrawer from "../SideDrawer";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";

import AuthGoogle from "./AuthGoogle";

import { getLoggedUser } from "../../services";

class Auth extends React.Component {
  state = { visible: false };

  constructor(props) {
    super(props);
    this.props.isLoggedInUser();
    this.state = {
      openLoginForm: false,
      openRegisterForm: false,
      openSideDrawer: false,
      sideDrawerType: "",
      sideDrawerTitle: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.login.data &&
      this.props.login.data !== prevProps.login.data
    ) {
      this.setState({
        openSideDrawer: false,
      });
    }
    if (
      this.props.login.isSuccess &&
      prevProps.login.isSuccess != this.props.login.isSuccess
    ) {
      let userInfo = getLoggedUser();

      if (userInfo !== false) {
      } else {
        this.props.history.push("/");
      }

      // if (
      //   this.props.login &&
      //   this.props.login.data &&
      //   this.props.login.data.role
      // ) {
      //   if (this.props.login.data.role == "masteradmin") {
      //     console.log("------1");
      //     this.props.history.push("/myshortshout");
      //   } else {
      //     console.log("------2");
      //     this.props.history.push("/myshortshout");
      //   }
      // }
    }
  }

  _openLoginForm = () => {
    this.setState({
      openSideDrawer: true,
      openLoginForm: true,
      sideDrawerType: "login",
      sideDrawerTitle: "Login",
    });
  };

  _openRegisterForm = () => {
    this.setState({
      openSideDrawer: true,
      openRegisterForm: true,
      sideDrawerType: "register",
      sideDrawerTitle: "Register",
    });
  };

  _closeSideDrawer = () => {
    this.setState({
      openSideDrawer: false,
      openLoginForm: false,
      openRegisterForm: false,
    });
  };

  _doRegister = (data) => {
    this.props.registerRequest(data);
  };

  _doLogin = (data) => {
    this.props.loginRequest(data);
  };

  _doResetPassword = (data) => {
    this.props.resetPasswordRequest(data);
  };

  _doLogout = (values) => {
    this.props.logoutRequest();
  };

  handleMenuClick = (item) => {
    switch (item.key) {
      case "home":
        this.props.history.push("/");
        break;
      case "dashboard":
        this.props.history.push("/myshortshout");
        break;
      case "influencers":
        this.props.history.push("/influencers");
        break;
      case "plansbilling":
        this.props.history.push("/plansbilling");
        break;
      case "masteradmin":
        this.props.history.push("/masteradmin");
        break;
      case "logout":
        this._doLogout();
        break;
      default:
        break;
    }
  };

  render() {
    let { loggedUserData } = this.props;
    let loggedUserRole = loggedUserData.role;
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        {/* <Menu.Item key="home">Home</Menu.Item>        
        <Menu.Item key="influencers">Influencers</Menu.Item> */}
        <Menu.Item key="dashboard">Dashboard</Menu.Item>
        {loggedUserRole === "masteradmin" ? (
          <Menu.Item key="masteradmin">Admin</Menu.Item>
        ) : null}
        <Menu.Item key="logout">Log Out</Menu.Item>
      </Menu>
    );

    let openSideDrawer = false;
    if (this.state.openLoginForm || this.state.openRegisterForm) {
      openSideDrawer = true;
    }

    return (
      <div>
        {/* <Link className="header-extra-link" to="/home-shortshout">
          Shortshout
        </Link> */}
        {/* <Link className="header-extra-link" to="/home-influencers">
          Influencers
        </Link> */}
        {/* <Link className="header-extra-link" to="/home-brands">
          Campaigns
        </Link> */}
        {/* <Link className="header-extra-link" to="/list-influencers">
          Active Influencers
        </Link> */}
        {/* <Link className="header-extra-link" to="/activeCampaigns">
          Active Campaigns
        </Link> */}
        {loggedUserData &&
        loggedUserData.token &&
        loggedUserData.email &&
        loggedUserData.name ? (
          <>
            <Link className="header-extra-link" to="/myshortshout">
              Dashboard
            </Link>
            Hi {loggedUserData.name} &nbsp;&nbsp;
            <Dropdown overlay={menu} trigger={["click"]}>
              <Avatar
                className="header-auth-avatar"
                size="large"
                icon={<UserOutlined />}
              />
            </Dropdown>
            {/* <a onClick={() => this.props.history.push("/")}>
              <HomeOutlined />
            </a>{" "}
            &nbsp; | &nbsp;
            <a onClick={() => this.props.history.push("/myshortshout")}>
              Dashboard
            </a>{" "}
            &nbsp; | &nbsp;
            <Link to="/influencers">Influencers</Link>
            &nbsp; | &nbsp;
            {loggedUserRole === "masteradmin" ? (
              <>
                <a onClick={() => this.props.history.push("/masteradmin")}>
                  Admin
                </a>{" "}
                &nbsp; | &nbsp;
              </>
            ) : null}
            <a onClick={this._doLogout}>Log Out</a> */}
          </>
        ) : (
          <>
            <Link to="/login">
              <Button
                type="link"
                className="btn-header-login"
                // onClick={this._openLoginForm}
              >
                Login
              </Button>
            </Link>
            {/* <Button
              type="primary"
              className="btn-login-register"
              onClick={this._openRegisterForm}
            >
              Register
            </Button>
            <div id="header-google-login">
              <AuthGoogle />
            </div> */}
          </>
        )}

        <SideDrawer
          title={this.state.sideDrawerTitle}
          open={this.state.openSideDrawer}
          onSideDrawerClose={this._closeSideDrawer}
        >
          {this.state.openLoginForm ? (
            <LoginForm
              login={this.props.login}
              resetPassword={this.props.resetPassword}
              doLogin={this._doLogin}
              doResetPassword={this._doResetPassword}
            />
          ) : (
            <RegisterForm
              register={this.props.register}
              doRegister={this._doRegister}
            />
          )}
        </SideDrawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.auth.login,
  register: state.auth.register,
  resetPassword: state.auth.resetPassword,
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  registerRequest: (data) => dispatch(actions.registerRequest(data)),
  loginRequest: (data) => dispatch(actions.loginRequest(data)),
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  logoutRequest: (data) => dispatch(actions.logoutRequest(data)),
  resetPasswordRequest: (data) => dispatch(actions.resetPasswordRequest(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
