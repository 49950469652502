import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Layout } from "antd";

import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";
import PageTitle from "../components/PageTitle";

import MenuInfluencers from "../components/MenuInfluencers";

import AuthInstagram from "../components/influencers/AuthInstagram";

import * as actions from "../redux/actions";

const { Content } = Layout;

function SocialProfileAuth(props) {
  const saveInstagramAccounts = (data) => {
    props.saveInstagramAccountsRequest(data);
  };

  const initiateTwitterAuth = () => {
    props.twitterAuthRequest();
  };

  const saveYoutubeAccounts = (data) => {
    props.saveYoutubeAccountsRequest(data);
  };

  return (
    <Layout id="page-influencers">
      <HeaderMain />
      <Content className="site-layout">
        <MenuInfluencers />
        <PageTitle title="Connect Social Profiles" />
        <br />
        <br />
        <AuthInstagram
          saveInstagramAccounts={saveInstagramAccounts}
          initiateTwitterAuth={initiateTwitterAuth}
          saveYoutubeAccounts={saveYoutubeAccounts}
        />
      </Content>
      <FooterMain />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  login: state.auth.login,
  // instagramPages: state.influencers.myInfluencerPages.data.instagramPages || [],
  myInfluencerPages: state.influencers.myInfluencerPages || false,
});

const mapDispatchToProps = (dispatch) => ({
  saveInstagramAccountsRequest: (data) =>
    dispatch(actions.saveInstagramAccountsRequest(data)),
  saveYoutubeAccountsRequest: (data) =>
    dispatch(actions.saveYoutubeAccountsRequest(data)),
  twitterAuthRequest: (data) => dispatch(actions.twitterAuthRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SocialProfileAuth)
);
