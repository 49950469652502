import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  myStats: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
};

/* MY STATS */
const myStatsRequest = (state, action) =>
  update(state, {
    myStats: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const myStatsSuccess = (state, action) =>
  update(state, {
    myStats: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });

const myStatsError = (state, action) =>
  update(state, {
    myStats: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });
/* MY STATS */

export default handleActions(
  {
    [constants.MY_STATS_REQUEST]: myStatsRequest,
    [constants.MY_STATS_SUCCESS]: myStatsSuccess,
    [constants.MY_STATS_ERROR]: myStatsError,
  },
  initialState
);
