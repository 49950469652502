import axios from "axios";
import jwt from "jsonwebtoken";
import { base_url, jwtsecret } from "./config";
import { notification } from "antd";

export const fireApi = function (method, api_url, data, headers) {
  headers = {
    ...headers,
    Authorization: getUserToken(),
    Accept: "application/json",
    "Content-Type": "application/json",
    // withCredentials: true,
    "Cache-Control": "no-cache",
  };

  const url = base_url + api_url;
  if (method === "GET") {
    return axios.get(url, { headers });
  } else if (method === "POST") {
    return axios.post(url, data, { headers });
  } else if (method === "PUT") {
    return axios.put(url, data, { headers });
  } else if (method === "DELETE") {
    return axios.delete(url, { data, headers });
  }
};

export const showNotification = ({ type, title, message }) => {
  notification[type]({
    duration: 4,
    message: title,
    description: message,
  });
};

export const setLoggedInSession = function (tokenVal) {
  // sessionStorage.setItem("shortshout-auth", tokenVal);
  localStorage.setItem("shortshout-auth", tokenVal);
};

export const clearLoggedInSession = function () {
  // sessionStorage.removeItem("shortshout-auth");
  // sessionStorage.removeItem("temp-isEmailVerified");
  localStorage.removeItem("shortshout-auth");
  localStorage.removeItem("temp-isEmailVerified");
  window.location.replace("/");
};

export const getLoggedUser = function () {
  // let token = sessionStorage.getItem("shortshout-auth");
  let token = localStorage.getItem("shortshout-auth");
  if (token) {
    let decodedToken = jwt.verify(token, jwtsecret);
    if (decodedToken && decodedToken.email) {
      decodedToken.token = token;
      return decodedToken;
    }
  }
  return false;
};

export const getUserToken = function () {
  // return sessionStorage.getItem("shortshout-auth") || "";
  return localStorage.getItem("shortshout-auth") || "";
};

export const tempVariableToHold_isEmailVerified = function (val) {
  // sessionStorage.setItem("temp-isEmailVerified", val);
  localStorage.setItem("temp-isEmailVerified", val);
};

export const get_tempVariableToHold_isEmailVerified = function () {
  // return sessionStorage.getItem("temp-isEmailVerified") || false;
  return localStorage.getItem("temp-isEmailVerified") || false;
};

export const getUserShortShoutUrl = function (user) {
  return process.env.REACT_APP_WEB_BASE_URL + "/" + user.username;
};

export const getUserInstagramLink = (username) => {
  return "https://www.instagram.com/" + username;
};

export const getUserTwitterLink = (username) => {
  return "https://twitter.com/" + username;
};

export const getUserYoutubeLink = (channelId) => {
  return "https://www.youtube.com/channel/" + channelId;
};

export const getYoutubeAuthorizationLink = () => {
  //let redirectUrl = "http://localhost:3000/influencers/youtubeAuthCallback"; // for local
  let redirectUrl = "https://shortshout.in/influencers/youtubeAuthCallback"; // for server
  let scope = "https://www.googleapis.com/auth/youtube.readonly";
  let url =
    "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=" +
    encodeURIComponent(scope) +
    "&response_type=code&client_id=24861275035-5cdk7vg146o1sghnv1qq3q8qlg4jkl14.apps.googleusercontent.com&redirect_uri=" +
    encodeURIComponent(redirectUrl) +
    "&include_granted_scopes=true";
  return url;
};
