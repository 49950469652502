import { createAction } from "redux-actions";
import constants from "./constants";

export const showProgressBar = createAction(constants.SHOW_PROGRESS_BAR);

export const registerRequest = createAction(constants.REGISTER_REQUEST);
export const registerSuccess = createAction(constants.REGISTER_SUCCESS);
export const registerError = createAction(constants.REGISTER_ERROR);

export const loginRequest = createAction(constants.LOGIN_REQUEST);
export const loginSuccess = createAction(constants.LOGIN_SUCCESS);
export const loginError = createAction(constants.LOGIN_ERROR);

export const isLoggedInUser = createAction(constants.IS_LOGGEDIN_USER);

export const logoutRequest = createAction(constants.LOGOUT_REQUEST);
export const logoutRequestSuccess = createAction(
  constants.LOGOUT_REQUEST_SUCCESS
);
export const logoutRequestError = createAction(constants.LOGOUT_REQUEST_ERROR);

export const newLinkRequest = createAction(constants.NEW_LINK_REQUEST);
export const newLinkSuccess = createAction(constants.NEW_LINK_SUCCESS);
export const newLinkError = createAction(constants.NEW_LINK_ERROR);

export const updateLinkRequest = createAction(constants.UPDATE_LINK_REQUEST);
export const updateLinkSuccess = createAction(constants.UPDATE_LINK_SUCCESS);
export const updateLinkError = createAction(constants.UPDATE_LINK_ERROR);

export const myLinksRequest = createAction(constants.MY_LINKS_REQUEST);
export const myLinksSuccess = createAction(constants.MY_LINKS_SUCCESS);
export const myLinksError = createAction(constants.MY_LINKS_ERROR);

export const getPublicShortShoutRequest = createAction(
  constants.GET_PUBLIC_SHORT_SHOUT_REQUEST
);
export const getPublicShortShoutSuccess = createAction(
  constants.GET_PUBLIC_SHORT_SHOUT_SUCCESS
);
export const getPublicShortShoutError = createAction(
  constants.GET_PUBLIC_SHORT_SHOUT_ERROR
);

export const deleteLinkRequest = createAction(constants.DELETE_LINK_REQUEST);
export const deleteLinkSuccess = createAction(constants.DELETE_LINK_SUCCESS);
export const deleteLinkError = createAction(constants.DELETE_LINK_ERROR);

export const resetPasswordRequest = createAction(
  constants.RESET_PASSWORD_REQUEST
);
export const resetPasswordSuccess = createAction(
  constants.RESET_PASSWORD_SUCCESS
);
export const resetPasswordError = createAction(constants.RESET_PASSWORD_ERROR);

export const updateLinkCountRequest = createAction(
  constants.UPDATE_LINK_CLICK_COUNT_REQUEST
);
export const updateLinkCountSuccess = createAction(
  constants.UPDATE_LINK_CLICK_COUNT_SUCCESS
);
export const updateLinkCountError = createAction(
  constants.UPDATE_LINK_CLICK_COUNT_ERROR
);

export const updateLinkStatusRequest = createAction(
  constants.UPDATE_LINK_STATUS_REQUEST
);
export const updateLinkStatusSuccess = createAction(
  constants.UPDATE_LINK_STATUS_SUCCESS
);
export const updateLinkStatusError = createAction(
  constants.UPDATE_LINK_STATUS_ERROR
);

export const verifyEmailRequest = createAction(constants.VERIFY_EMAIL_REQUEST);
export const verifyEmailSuccess = createAction(constants.VERIFY_EMAIL_SUCCESS);
export const verifyEmailError = createAction(constants.VERIFY_EMAIL_ERROR);

export const sendEmailVerifyCodeRequest = createAction(
  constants.SEND_EMAIL_VERIFY_CODE_REQUEST
);
export const sendEmailVerifyCodeSuccess = createAction(
  constants.SEND_EMAIL_VERIFY_CODE_SUCCESS
);
export const sendEmailVerifyCodeError = createAction(
  constants.SEND_EMAIL_VERIFY_CODE_ERROR
);

export const getRecentlyAddedUsersRequest = createAction(
  constants.GET_RECENTLY_ADDED_USERS_REQUEST
);
export const getRecentlyAddedUsersSuccess = createAction(
  constants.GET_RECENTLY_ADDED_USERS_SUCCESS
);
export const getRecentlyAddedUsersError = createAction(
  constants.GET_RECENTLY_ADDED_USERS_ERROR
);

export const myStatsRequest = createAction(constants.MY_STATS_REQUEST);
export const myStatsSuccess = createAction(constants.MY_STATS_SUCCESS);
export const myStatsError = createAction(constants.MY_STATS_ERROR);

export const adminGetLatestUsersRequest = createAction(
  constants.ADMIN_GET_LATEST_USERS_REQUEST
);
export const adminGetLatestUsersSuccess = createAction(
  constants.ADMIN_GET_LATEST_USERS_SUCCESS
);
export const adminGetLatestUsersError = createAction(
  constants.ADMIN_GET_LATEST_USERS_ERROR
);

export const adminGetLatestLinksRequest = createAction(
  constants.ADMIN_GET_LATEST_LINKS_REQUEST
);
export const adminGetLatestLinksSuccess = createAction(
  constants.ADMIN_GET_LATEST_LINKS_SUCCESS
);
export const adminGetLatestLinksError = createAction(
  constants.ADMIN_GET_LATEST_LINKS_ERROR
);

export const adminGetCompleteStatsRequest = createAction(
  constants.ADMIN_GET_COMPLETE_STATS_REQUEST
);
export const adminGetCompleteStatsSuccess = createAction(
  constants.ADMIN_GET_COMPLETE_STATS_SUCCESS
);
export const adminGetCompleteStatsError = createAction(
  constants.ADMIN_GET_COMPLETE_STATS_ERROR
);

export const adminSendDoVerificationEmailRequest = createAction(
  constants.ADMIN_SEND_DO_VERIFICATION_EMAIL_REQUEST
);
export const adminSendDoVerificationEmailSuccess = createAction(
  constants.ADMIN_SEND_DO_VERIFICATION_EMAIL_SUCCESS
);
export const adminSendDoVerificationEmailError = createAction(
  constants.ADMIN_SEND_DO_VERIFICATION_EMAIL_ERROR
);

export const addUsernameRequest = createAction(constants.ADD_USERNAME_REQUEST);
export const addUsernameSuccess = createAction(constants.ADD_USERNAME_SUCCESS);
export const addUsernameError = createAction(constants.ADD_USERNAME_ERROR);

export const getRefreshTokenRequest = createAction(
  constants.GET_REFRESH_TOKEN_REQUEST
);
export const getRefreshTokenSuccess = createAction(
  constants.GET_REFRESH_TOKEN_SUCCESS
);
export const getRefreshTokenError = createAction(
  constants.GET_REFRESH_TOKEN_ERROR
);

export const updatePasswordRequest = createAction(
  constants.UPDATE_PASSWORD_REQUEST
);
export const updatePasswordSuccess = createAction(
  constants.UPDATE_PASSWORD_SUCCESS
);
export const updatePasswordError = createAction(
  constants.UPDATE_PASSWORD_ERROR
);

export const uploadAvatarRequest = createAction(
  constants.UPLOAD_AVATAR_REQUEST
);
export const uploadAvatarSuccess = createAction(
  constants.UPLOAD_AVATAR_SUCCESS
);
export const uploadAvatarError = createAction(constants.UPLOAD_AVATAR_ERROR);

export const myAvatarRequest = createAction(constants.MY_AVATAR_REQUEST);
export const myAvatarSuccess = createAction(constants.MY_AVATAR_SUCCESS);
export const myAvatarError = createAction(constants.MY_AVATAR_ERROR);

/* influencers */
export const saveInstagramAccountsRequest = createAction(
  constants.SAVE_INSTAGRAM_ACCOUNTS_REQUEST
);
export const saveInstagramAccountsSuccess = createAction(
  constants.SAVE_INSTAGRAM_ACCOUNTS_SUCCESS
);
export const saveInstagramAccountsError = createAction(
  constants.SAVE_INSTAGRAM_ACCOUNTS_ERROR
);

/* get my influencers pages */
export const myInfluencerPagesRequest = createAction(
  constants.MY_INFLUENCER_PAGES_REQUEST
);
export const myInfluencerPagesSuccess = createAction(
  constants.MY_INFLUENCER_PAGES_SUCCESS
);
export const myInfluencerPagesError = createAction(
  constants.MY_INFLUENCER_PAGES_ERROR
);

/* public influencers pages */
export const publicInfluencerPagesRequest = createAction(
  constants.PUBLIC_INFLUENCER_PAGES_REQUEST
);
export const publicInfluencerPagesSuccess = createAction(
  constants.PUBLIC_INFLUENCER_PAGES_SUCCESS
);
export const publicInfluencerPagesError = createAction(
  constants.PUBLIC_INFLUENCER_PAGES_ERROR
);

/* update influencers instagram */
export const updateInfluencerInstagramRequest = createAction(
  constants.UPDATE_INFLUENCER_INSTAGRAM_REQUEST
);
export const updateInfluencerInstagramSuccess = createAction(
  constants.UPDATE_INFLUENCER_INSTAGRAM_SUCCESS
);
export const updateInfluencerInstagramError = createAction(
  constants.UPDATE_INFLUENCER_INSTAGRAM_ERROR
);

/* twitter auth */
export const twitterAuthRequest = createAction(constants.TWITTER_AUTH_REQUEST);
export const twitterAuthSuccess = createAction(constants.TWITTER_AUTH_SUCCESS);
export const twitterAuthError = createAction(constants.TWITTER_AUTH_ERROR);

/* twitter auth  verify*/
export const twitterAuthVerifyRequest = createAction(
  constants.TWITTER_AUTH_VERIFY_REQUEST
);
export const twitterAuthVerifySuccess = createAction(
  constants.TWITTER_AUTH_VERIFY_SUCCESS
);
export const twitterAuthVerifyError = createAction(
  constants.TWITTER_AUTH_VERIFY_ERROR
);

/* update influencers TWITTER */
export const updateInfluencerTwitterRequest = createAction(
  constants.UPDATE_INFLUENCER_TWITTER_REQUEST
);
export const updateInfluencerTwitterSuccess = createAction(
  constants.UPDATE_INFLUENCER_TWITTER_SUCCESS
);
export const updateInfluencerTwitterError = createAction(
  constants.UPDATE_INFLUENCER_TWITTER_ERROR
);

/* NEW CAMPAIGN */
export const newCampaignRequest = createAction(constants.NEW_CAMPAIGN_REQUEST);
export const newCampaignSuccess = createAction(constants.NEW_CAMPAIGN_SUCCESS);
export const newCampaignError = createAction(constants.NEW_CAMPAIGN_ERROR);

/* MY CAMPAIGNS */
export const myCampaignsRequest = createAction(constants.MY_CAMPAIGNS_REQUEST);
export const myCampaignsSuccess = createAction(constants.MY_CAMPAIGNS_SUCCESS);
export const myCampaignsError = createAction(constants.MY_CAMPAIGNS_ERROR);

/* MY CAMPAIGN */
export const myCampaignRequest = createAction(constants.MY_CAMPAIGN_REQUEST);
export const myCampaignSuccess = createAction(constants.MY_CAMPAIGN_SUCCESS);
export const myCampaignError = createAction(constants.MY_CAMPAIGN_ERROR);

/* UPDATE MY CAMPAIGN */
export const updateMyCampaignRequest = createAction(
  constants.UPDATE_MY_CAMPAIGN_REQUEST
);
export const updateMyCampaignSuccess = createAction(
  constants.UPDATE_MY_CAMPAIGN_SUCCESS
);
export const updateMyCampaignError = createAction(
  constants.UPDATE_MY_CAMPAIGN_ERROR
);

/* ACTIVE CAMPAIGNS */
export const activeCampaignsRequest = createAction(
  constants.ACTIVE_CAMPAIGNS_REQUEST
);
export const activeCampaignsSuccess = createAction(
  constants.ACTIVE_CAMPAIGNS_SUCCESS
);
export const activeCampaignsError = createAction(
  constants.ACTIVE_CAMPAIGNS_ERROR
);

/* apply CAMPAIGN */
export const applyCampaignRequest = createAction(
  constants.APPLY_CAMPAIGN_REQUEST
);
export const applyCampaignSuccess = createAction(
  constants.APPLY_CAMPAIGN_SUCCESS
);
export const applyCampaignError = createAction(constants.APPLY_CAMPAIGN_ERROR);

/* save youtube channels */
export const saveYoutubeAccountsRequest = createAction(
  constants.SAVE_YOUTUBE_ACCOUNTS_REQUEST
);
export const saveYoutubeAccountsSuccess = createAction(
  constants.SAVE_YOUTUBE_ACCOUNTS_SUCCESS
);
export const saveYoutubeAccountsError = createAction(
  constants.SAVE_YOUTUBE_ACCOUNTS_ERROR
);

/* update influencers youtube */
export const updateInfluencerYoutubeRequest = createAction(
  constants.UPDATE_INFLUENCER_YOUTUBE_REQUEST
);
export const updateInfluencerYoutubeSuccess = createAction(
  constants.UPDATE_INFLUENCER_YOUTUBE_SUCCESS
);
export const updateInfluencerYoutubeError = createAction(
  constants.UPDATE_INFLUENCER_YOUTUBE_ERROR
);
