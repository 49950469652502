import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Layout, Tabs, Button } from "antd";
import { InstagramOutlined } from "@ant-design/icons";

import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import AuthInstagram from "../components/influencers/AuthInstagram";
import PublicListInfluencersInstagram from "../components/publicListInfluencers/PublicListInfluencersInstagram";

import * as actions from "../redux/actions";
import { twitterAuthRequest } from "../redux/influencers/action";

const { Content } = Layout;
const { TabPane } = Tabs;

function AuthTwitterCallBack(props) {
  const { publicInfluencerPages, loggedUserData, twitterAuthVerify } = props;

  const instagramPages =
    (publicInfluencerPages.data && publicInfluencerPages.data.instagramPages) ||
    [];

  const [hasInstagramPages, setHasInstagramPages] = useState(false);
  const [activeTab, setActiveTab] = useState("tab-instagram");
  useEffect(() => {
    const { history } = props;
    const { location } = history;
    const { search } = location;
    if (
      search.indexOf("oauth_token") !== -1 &&
      search.indexOf("oauth_verifier") !== -1
    ) {
      const urlParams = new URLSearchParams(search);
      let oauth_token = urlParams.get("oauth_token");
      let oauth_verifier = urlParams.get("oauth_verifier");
      if (oauth_token !== "" && oauth_verifier !== "") {
        props.twitterAuthVerifyRequest({
          oauth_token: oauth_token,
          oauth_verifier: oauth_verifier,
        });
      }
    }

    // props.publicInfluencerPagesRequest();
  }, []);

  useEffect(() => {
    if (publicInfluencerPages && publicInfluencerPages.isLoading === false) {
      if (instagramPages.length > 0) {
        setHasInstagramPages(true);
        setActiveTab("tab-instagram");
      }
    }
  }, [instagramPages]);

  useEffect(() => {
    if (twitterAuthVerify && twitterAuthVerify.isSuccess) {
      props.history.push("/influencers");
    }
  }, [twitterAuthVerify]);

  return (
    <Layout id="page-public-influencers">
      <HeaderMain />
      <Content className="site-layout"></Content>
      <FooterMain />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  login: state.auth.login,
  loggedUserData: state.auth.login.data || false,
  // instagramPages: state.influencers.myInfluencerPages.data.instagramPages || [],
  publicInfluencerPages: state.influencers.publicInfluencerPages || false,
  twitterAuthVerify: state.influencers.twitterAuthVerify || false,
});

const mapDispatchToProps = (dispatch) => ({
  publicInfluencerPagesRequest: (data) =>
    dispatch(actions.publicInfluencerPagesRequest(data)),
  twitterAuthVerifyRequest: (data) =>
    dispatch(actions.twitterAuthVerifyRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthTwitterCallBack)
);
