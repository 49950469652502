import React from "react";
import { withRouter, Link } from "react-router-dom";

import { Form, Input, Select, Button, AutoComplete, Spin } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import AuthGoogle from "./AuthGoogle";

const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;
const residences = [
  {
    value: "zhejiang",
    label: "Zhejiang",
    children: [
      {
        value: "hangzhou",
        label: "Hangzhou",
        children: [
          {
            value: "xihu",
            label: "West Lake",
          },
        ],
      },
    ],
  },
  {
    value: "jiangsu",
    label: "Jiangsu",
    children: [
      {
        value: "nanjing",
        label: "Nanjing",
        children: [
          {
            value: "zhonghuamen",
            label: "Zhong Hua Men",
          },
        ],
      },
    ],
  },
];
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select
      style={{
        width: 70,
      }}
    >
      <Option value="86">+86</Option>
      <Option value="87">+87</Option>
    </Select>
  </Form.Item>
);

class RegisterForm extends React.Component {
  onFinish = (values) => {
    this.props.doRegister(values);
  };

  render() {
    let { isLoading } = this.props.register;
    return (
      <>
        {/* <div style={{ textAlign: "center" }}>
          <AuthGoogle />
          <br />
          OR
          <br />
          <br />
        </div> */}
        <Form
          {...formItemLayout}
          // form={form}
          className="form-register"
          name="register"
          onFinish={this.onFinish}
          initialValues={{
            residence: ["zhejiang", "hangzhou", "xihu"],
            prefix: "86",
          }}
          scrollToFirstError
        >
          <h1 className="form-heading">Register</h1>
          <Form.Item
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: "Please input your username.",
              },
              {
                min: 3,
                message: "Username cannot be less than 3 characters.",
              },
              {
                max: 30,
                message: "Username cannot exceed 30 characters.",
              },
              {
                whitespace: true,
                message: "Space cannot be entered.",
              },
              {
                message: "Only alphabets and numbers can be entered.",
                pattern: /^[A-Za-z0-9]+$/,
              },
            ]}
          >
            <Input autoComplete="off" addonBefore="https://shortshout.in/" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: "Enter your full name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                min: 4,
                message: "Password cannot be less than 4 characters",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          {/* <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject("Should accept agreement"),
            },
          ]}
          {...tailFormItemLayout}
        >
          <Checkbox>
            I have read the <a href="">agreement</a>
          </Checkbox>
        </Form.Item> */}
          <Form.Item {...tailFormItemLayout}>
            {isLoading ? (
              <Spin />
            ) : (
              <>
                <Button
                  type="primary"
                  className="button-register"
                  htmlType="submit"
                >
                  Register
                </Button>
                <br />
                <br />
                <Link to="/login">Already Registered. Login!!</Link>
              </>
            )}
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default RegisterForm;
