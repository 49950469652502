import React from "react";
import { Row, Col, Layout, Tabs } from "antd";
import { DashboardOutlined, ProfileOutlined } from "@ant-design/icons";

import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import Dashboard from "../components/dashboard";

import FormUpdatePassword from "../components/forms/FormUpdatePassword";

const { Header, Content, Footer } = Layout;

const { TabPane } = Tabs;

class MyShortShout extends React.Component {
  render() {
    return (
      <Layout id="page-myShortshout">
        <HeaderMain />
        <Content className="site-layout">
          <Tabs
            defaultActiveKey="1"
            className="dashboard-wrapper-tabs"
            centered
          >
            <TabPane
              tab={
                <span>
                  <DashboardOutlined />
                  Dashboard
                </span>
              }
              key="1"
            >
              <Dashboard />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <ProfileOutlined />
                  My Profile
                </span>
              }
              key="2"
            >
              <FormUpdatePassword />
            </TabPane>
          </Tabs>
        </Content>
        <FooterMain />
      </Layout>
    );
  }
}

export default MyShortShout;
