import React from "react";
import { Link } from "react-router-dom";

import { Row, Col, Layout, Steps } from "antd";
import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";
import Sample from "../images/sample-short-shout.png";
import InstagramSample from "../images/instagram-sample.png";
import RecentlyAddedUsers from "../components/RecentlyAddedUsers";

const { Content } = Layout;
const { Step } = Steps;

class HomeShortshout extends React.Component {
  render() {
    return (
      <Layout id="page-home-shortshout">
        <HeaderMain />
        <Content className="site-layout">
          <Row>
            <Col xl={12} md={12} xs={24} className="home-page-info-content">
              <div>
                <div className="home-page-block-info">
                  Get your public URL like -{" "}
                  <a href="https://shortshout.in/shortshout" target="_BLANK">
                    https://shortshout.in/shortshout
                  </a>
                  <br />
                  <br />
                  <Steps direction="vertical" size="small">
                    <Step
                      status="finish"
                      title="Sign Up ( Yes It's Free!! )"
                      description="Sign up with your email or login with Google."
                    />
                    <Step
                      status="finish"
                      title="Add Links"
                      description="Add your social and professional links."
                    />
                    <Step
                      status="finish"
                      title="Share & Grow"
                      description={
                        <span>
                          You are done.
                          <br />
                          Your single link for multiple links is ready to share
                          with the web world.
                        </span>
                      }
                    />
                    <Step
                      status="finish"
                      title="Manage and View Links Reports"
                      description={
                        <span>
                          View your page impressions and clicks report.
                        </span>
                      }
                    />
                  </Steps>
                </div>

                <div className="home-page-block-info">
                  Features
                  <br />
                  <br />
                  <Steps direction="vertical" size="small">
                    <Step
                      status="finish"
                      title="It's Free"
                      description="Yes it's free. You don't have to pay anything."
                    />
                    <Step
                      status="finish"
                      title="Add Multiple Links"
                      description={
                        <span>
                          Add multiple links including
                          <br />
                          social - facebook, instagram, twitter etc.
                          <br />
                          professional ( Linkedin ), services, websites etc.
                        </span>
                      }
                    />
                    <Step
                      status="finish"
                      title="Your Dashboard"
                      description="Add/Update/Pause/Delete/ links. View Reports."
                    />
                    <Step
                      status="finish"
                      title="Reports"
                      description={
                        <span>
                          View real time page impressions and clicks Report.{" "}
                          <br />
                          You can also view reports on Android app.
                          <a
                            href="https://play.google.com/store/apps/details?id=com.shortshout.shortshout"
                            target="_BLANK"
                            title="Android App on play store"
                          >
                            &nbsp; Click Here To Download
                          </a>
                        </span>
                      }
                    />
                  </Steps>
                </div>
              </div>
            </Col>
            <Col xl={12} md={12} xs={24}>
              <Row>
                <Col xl={12} md={12} xs={24} className="images">
                  <img
                    id="image1"
                    alt="shortshout.in public link view"
                    src={Sample}
                  />
                </Col>
                <Col xl={12} md={12} xs={24} className="images">
                  <img
                    id="image2"
                    alt="shortshout.in instagram link in bio"
                    src={InstagramSample}
                  />
                </Col>
              </Row>
              <div className="home-page-block-info">
                {" "}
                Connect Instagram followers to your various content using single
                link.
              </div>

              <div className="home-page-block-info">
                > Single link to show multiple links on your instagram bio
              </div>
            </Col>
          </Row>

          <RecentlyAddedUsers />
        </Content>

        <FooterMain />
      </Layout>
    );
  }
}

export default HomeShortshout;
