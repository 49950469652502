import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showNotification } from "../../services";

export function* myStatsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/dashboard/getMyStats`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.myStatsSuccess(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.myStatsError(e.response.data));
  }
}
