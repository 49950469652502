import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, getLoggedUser, showNotification } from "../../services";

export function* saveInstagramAccountsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/saveInstagramAccounts`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.myInfluencerPagesRequest());
      yield put(actions.saveInstagramAccountsSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.saveInstagramAccountsError(e.response.data));
  }
}

export function* myInfluencerPagesRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/getMyInfluencerPages`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.myInfluencerPagesSuccess(response.data.data));
      // toast.success(response.data.message);
    }
  } catch (e) {
    yield put(actions.myInfluencerPagesError(e.response.data));
  }
}

export function* publicInfluencerPagesRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/getPublicInfluencerPages`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.publicInfluencerPagesSuccess(response.data.data));
      // toast.success(response.data.message);
    }
  } catch (e) {
    yield put(actions.publicInfluencerPagesError(e.response.data));
  }
}

export function* updateInfluencerInstagramRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/updateInfluencerInstagram`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.myInfluencerPagesRequest());
      yield put(actions.updateInfluencerInstagramSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.updateInfluencerInstagramError(e.response.data));
  }
}

export function* twitterAuthRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/doTwitterAuth`,
      action.payload,
      header
    );
    if (response) {
      const { data } = response;
      if (data.data && data.data.authUrl) {
        window.location.replace(data.data.authUrl);
        // location.href = data.data.authUrl;
        // yield put(actions.myInfluencerPagesRequest());
        yield put(actions.twitterAuthSuccess(response.data.data));
      } else {
        yield put(actions.twitterAuthError());
      }
    }
  } catch (e) {
    yield put(actions.twitterAuthError());
  }
}

export function* twitterAuthVerifyRequest(action) {
  let loggedUser = getLoggedUser();

  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/doTwitterAuthVerify`,
      action.payload,
      header
    );
    if (response) {
      const { data } = response;
      if (data.error === 0) {
        // console.log(response.data);
        // console.log(response.data.data);
        // window.location.replace(data.data.authUrl);
        // location.href = data.data.authUrl;
        // yield put(actions.myInfluencerPagesRequest());
        yield put(actions.twitterAuthVerifySuccess(response.data.data));
        // show alert message here
      } else {
        yield put(actions.twitterAuthVerifyError());
        // show alert message here
      }
    }
  } catch (e) {
    yield put(actions.twitterAuthVerifyError());
  }
}

export function* updateInfluencerTwitterRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/updateInfluencerTwitter`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.myInfluencerPagesRequest());
      yield put(actions.updateInfluencerTwitterSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.updateInfluencerTwitterError(e.response.data));
  }
}

export function* newCampaignRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/newCampaign`,
      action.payload,
      header
    );
    // console.log(response);
    if (response) {
      const { error, message } = response.data;
      if (error) {
        showNotification({
          type: "error",
          title: message,
        });
      } else {
        yield put(actions.myCampaignsRequest());
        yield put(actions.newCampaignSuccess(response.data.data));
        showNotification({
          type: "success",
          title: message,
        });
      }
    }
  } catch (e) {
    yield put(actions.newCampaignError(e.response.data));
    showNotification({
      type: "error",
      title: e.response.data.message,
    });
  }
}

export function* myCampaignsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/myCampaigns`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.myCampaignsSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.myCampaignsError(e.response.data));
  }
}

export function* myCampaignRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/myCampaign`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.myCampaignSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.myCampaignError(e.response.data));
  }
}

export function* updateMyCampaignRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/updateMyCampaign`,
      action.payload,
      header
    );
    if (response) {
      yield put(
        actions.myCampaignRequest({
          campaignId: action.payload.campaignId,
        })
      );
      yield put(actions.myCampaignSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.myCampaignError(e.response.data));
  }
}

export function* activeCampaignsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/activeCampaigns`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.activeCampaignsSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.activeCampaignsError(e.response.data));
  }
}

export function* applyCampaignRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/applyCampaign`,
      action.payload,
      header
    );
    if (response) {
      let { error, message } = response.data;
      let notifyType = "success";
      if (error === 1) {
        notifyType = "error";
      }
      showNotification({
        type: notifyType,
        title: message,
      });
      yield put(actions.applyCampaignSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.applyCampaignError(e.response.data));
    showNotification({
      type: "error",
      title: e.response.data.message,
    });
  }
}

export function* saveYoutubeAccountsRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/saveYoutubeAccounts`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.myInfluencerPagesRequest());
      yield put(actions.saveYoutubeAccountsSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.saveYoutubeAccountsError(e.response.data));
  }
}

export function* updateInfluencerYoutubeRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/influencers/updateInfluencerYoutube`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.myInfluencerPagesRequest());
      yield put(actions.updateInfluencerYoutubeSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.updateInfluencerYoutubeError(e.response.data));
  }
}
