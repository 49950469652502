import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Layout } from "antd";

import HeaderMain from "../components/HeaderMain";
import PageTitle from "../components/PageTitle";
import FooterMain from "../components/FooterMain";

import CampaignsList from "../components/activeCampaigns/CampaignsList";

import * as actions from "../redux/actions";

const { Content } = Layout;

function ActiveCampaigns(props) {
  const { activeCampaigns } = props;

  const { campaigns } = activeCampaigns;

  let campaignsList = campaigns || [];

  useEffect(() => {
    props.activeCampaignsRequest();
  }, []);

  const onApplyCampaign = (campaign) => {
    props.applyCampaignRequest({
      campaignId: campaign._id,
    });
  };

  return (
    <Layout id="page-active-campaigns">
      <HeaderMain />
      <Content className="site-layout">
        <PageTitle title="Active Campaigns" />
        <br />
        <CampaignsList
          campaigns={campaignsList}
          applyCampaign={onApplyCampaign}
        />
      </Content>
      <FooterMain />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  login: state.auth.login,
  activeCampaigns: state.influencers.activeCampaigns.data || [],
});

const mapDispatchToProps = (dispatch) => ({
  activeCampaignsRequest: (data) =>
    dispatch(actions.activeCampaignsRequest(data)),
  applyCampaignRequest: (data) => dispatch(actions.applyCampaignRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActiveCampaigns)
);
