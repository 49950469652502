import React from "react";
import { Button } from "antd";
import { GoogleLogin } from "react-google-login";
import { GoogleOutlined } from "@ant-design/icons";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "../../redux/actions";

function AuthGoogle(props) {
  const onSuccessGoogle = (response) => {
    let payload = {
      isGoogleLogin: true,
      data: response,
    };
    props.loginRequest(payload);
  };
  const onErrorGoogle = (response) => {
    // console.log(response);
  };
  return (
    <div>
      <GoogleLogin
        clientId="24861275035-5cdk7vg146o1sghnv1qq3q8qlg4jkl14.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={onSuccessGoogle}
        onFailure={onErrorGoogle}
        cookiePolicy={"single_host_origin"}
        render={(renderProps) => (
          <Button
            className="google-login-button"
            icon={<GoogleOutlined />}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            Login In With Google
          </Button>
        )}
      />
    </div>
  );
}

// export default AuthGoogle;

const mapStateToProps = (state) => ({
  login: state.auth.login,
});

const mapDispatchToProps = (dispatch) => ({
  loginRequest: (data) => dispatch(actions.loginRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthGoogle)
);
