import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Layout, Collapse } from "antd";

import HeaderMain from "../components/HeaderMain";
import PageTitle from "../components/PageTitle";
import FooterMain from "../components/FooterMain";
import MenuInfluencers from "../components/MenuInfluencers";

import FormEditCampaign from "../components/manageCampaign/FormEditCampaign";
import CampaignDetails from "../components/manageCampaign/CampaignDetails";
import CampaignApplicants from "../components/manageCampaign/CampaignApplicants";

import * as actions from "../redux/actions";

const { Content } = Layout;
const { Panel } = Collapse;

function ManageCampaign(props) {
  const { myCampaign } = props;
  useEffect(() => {
    const { match } = props;
    const { params } = match;
    if (params && params.campaignId) {
      props.myCampaignRequest({
        campaignId: params.campaignId,
      });
    }
  }, []);

  const updateMyCampaign = (data) => {
    props.updateMyCampaignRequest(data);
  };

  return (
    <Layout id="page-manage-campaigns">
      <HeaderMain />
      <Content className="site-layout">
        <MenuInfluencers />
        <PageTitle title="Manage Campaign" />
        <br />
        <CampaignDetails campaign={myCampaign.campaign || {}} />
        <br />

        <Collapse>
          <Panel header="Edit Campaign" key="1">
            <FormEditCampaign
              campaign={myCampaign.campaign || {}}
              updateMyCampaign={updateMyCampaign}
            />
          </Panel>
        </Collapse>
        <CampaignApplicants
          campaignApplications={myCampaign.campaignApplications || {}}
        />
      </Content>
      <FooterMain />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  login: state.auth.login,
  myCampaign: state.influencers.myCampaign.data || [],
});

const mapDispatchToProps = (dispatch) => ({
  myCampaignRequest: (data) => dispatch(actions.myCampaignRequest(data)),
  updateMyCampaignRequest: (data) =>
    dispatch(actions.updateMyCampaignRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageCampaign)
);
