import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  Spin,
  Card,
  Avatar,
} from "antd";

import * as actions from "../../redux/actions";

const { Meta } = Card;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class FormUpdatePassword extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  submitForm = (values) => {
    this.props.updatePasswordRequest(values);
  };

  componentDidMount() {
    //this.props.getAllLocationsRequest();
    //this.props.getMyProfileRequest();
    // this.setState({
    //   editLinkData: this.props.editLinkData,
    // });
  }

  //   static getDerivedStateFromProps(props, current_state) {
  //     if (current_state.editLinkData !== props.editLinkData) {
  //       return {
  //         editLinkData: props.editLinkData,
  //       };
  //     }
  //     return null;
  //   }

  _renderUserProfileInfo = () => {
    const { loggedUserData } = this.props;
    const { profilePhoto, name, email } = loggedUserData;
    return (
      <Card className="my-profile-user-info">
        <Meta
          avatar={<Avatar src={profilePhoto} />}
          title={name}
          description={email}
        />
      </Card>
    );
  };

  render() {
    const { updatePassword } = this.props;
    return (
      <Row id="form-update-password">
        <Col xl={24} md={24} xs={24}>
          {this._renderUserProfileInfo()}

          <div className="form-password">
            <h3>Update Password</h3>
            <Form
              {...formItemLayout}
              name="editProfile"
              onFinish={this.submitForm}
              scrollToFirstError
            >
              <Form.Item
                name="oldPassword"
                label="Old Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your current password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                  {
                    min: 4,
                    message: "Password cannot be less than 4 characters",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={["newPassword"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                {updatePassword && updatePassword.isLoading ? (
                  <Spin />
                ) : (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Update Password
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    );
  }
}

// export default FormAddNewShortShout;

const mapStateToProps = (state) => ({
  updatePassword: state.auth.updatePassword || false,
  loggedUserData: state.auth.login.data || false,
  //   locations: state.locations.locations.data || [],
  //   myProfile: state.auth.myProfile.data || {},
});

const mapDispatchToProps = (dispatch) => ({
  //   getAllLocationsRequest: (data) =>
  //     dispatch(actions.getAllLocationsRequest(data)),
  //   newParkingRequest: (data) => dispatch(actions.newParkingRequest(data)),
  //   getMyProfileRequest: (data) => dispatch(actions.getMyProfileRequest(data)),
  updatePasswordRequest: (data) =>
    dispatch(actions.updatePasswordRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormUpdatePassword)
);
