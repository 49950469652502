import React from "react";
import { connect } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import { Row, Col, Layout, Button, Form, Input, Spin } from "antd";

import { UserOutlined, LockOutlined } from "@ant-design/icons";

import SideDrawer from "../SideDrawer";

import * as actions from "../../redux/actions";

const { Header, Footer } = Layout;

class FormEmailVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openVerifyEmailForm: false,
    };
  }

  _openSideDrawer = (e) => {
    e.preventDefault();
    this.setState({
      openVerifyEmailForm: true,
    });
  };

  _closeSideDrawer = () => {
    this.setState({
      openVerifyEmailForm: false,
    });
  };

  onFinishVerifyEmail = (values) => {
    this.props.verifyEmailRequest({
      code: values.code,
    });
  };
  render() {
    let isVerifyEmailLoading = this.props.verifyEmail.isLoading;
    let isSendEmailVerifyCodeLoading = this.props.sendEmailVerifyCode.isLoading;
    return (
      <Row className="email-verification-block">
        <Col xl={24}>
          Please verify your email id otherwise your public URL will not work
          after few days.{" "}
          <a href="" onClick={this._openSideDrawer}>
            Click Here To Verify Now!!
          </a>
        </Col>
        <SideDrawer
          placement="right"
          title="Verify your email id"
          open={this.state.openVerifyEmailForm}
          onSideDrawerClose={this._closeSideDrawer}
        >
          <Form
            name="verifyemail"
            className="verify-form"
            // initialValues={{
            //   remember: true,
            // }}
            onFinish={this.onFinishVerifyEmail}
          >
            <Form.Item
              name="code"
              label="Enter Verification Code"
              rules={[
                {
                  required: true,
                  message: "Please enter verification code!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              {isVerifyEmailLoading ? (
                <Spin />
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Click To Verify
                </Button>
              )}
            </Form.Item>
            <Form.Item>
              {isSendEmailVerifyCodeLoading ? (
                <Spin />
              ) : (
                <a
                  className="login-form-forgot"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.sendEmailVerifyCodeRequest();
                  }}
                >
                  Resend Secret Code!!
                </a>
              )}
            </Form.Item>
          </Form>
        </SideDrawer>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  verifyEmail: state.auth.verifyEmail,
  sendEmailVerifyCode: state.auth.sendEmailVerifyCode,
});

const mapDispatchToProps = (dispatch) => ({
  verifyEmailRequest: (data) => dispatch(actions.verifyEmailRequest(data)),
  sendEmailVerifyCodeRequest: (data) =>
    dispatch(actions.sendEmailVerifyCodeRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormEmailVerification);
