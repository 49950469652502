import React, { useState } from "react";
import {
  Form,
  Input,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from "antd";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const FormCreateCampaign = (props) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);

    props.createNewCampaign(values);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value: string) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        residence: ["zhejiang", "hangzhou", "xihu"],
        prefix: "86",
      }}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Campaign Name"
        // tooltip="What do you want others to call you?"
        rules={[
          {
            required: true,
            message: "Please input Campaign name!",
            whitespace: true,
          },
        ]}
      >
        <Input autoComplete="off" />
      </Form.Item>

      <Form.Item
        name="platform"
        label="Campaign Platform"
        // tooltip="What do you want others to call you?"
        rules={[
          {
            required: true,
            message: "Please select Campaign platform!",
            whitespace: true,
          },
        ]}
      >
        <Select>
          <Select.Option value="youtube">Youtube</Select.Option>
          <Select.Option value="twitter">Twitter</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="budget"
        label="Budget"
        // tooltip="What do you want others to call you?"
        rules={[
          {
            required: true,
            message: "Please enter your budget!",
            whitespace: true,
          },
        ]}
      >
        <Input autoComplete="off" type="number" prefix="₹" suffix="INR" />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[
          {
            required: true,
            message: "Please add description!",
            whitespace: true,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Create Campaign
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormCreateCampaign;
