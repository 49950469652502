import React from "react";
import { Card, Avatar, Popconfirm, Switch, Button } from "antd";
import { Bar, Line } from "react-chartjs-2";
import moment from "moment";

const { Meta } = Card;

class ManageLink extends React.Component {
  _onDeleteLink = () => {
    this.props.doDeleteLink(this.props.data);
  };

  _onEditLink = (e) => {
    e.preventDefault();
    this.props.onEditLink(this.props.data);
  };

  _changeLinkStatus = (newStatus) => {
    this.props.doLinkStatusChange({
      newStatus: newStatus,
      linkData: this.props.data,
    });
  };

  _renderLinkClickGraph = (data) => {
    if (data && data.linkclickslogs && data.linkclickslogs.length > 0) {
      let rawData = data.linkclickslogs;

      let raw_labels = [];
      let raw_datasets_data = [];

      rawData.map((item) => {
        let date = item.date;
        date = moment(date, "DD-MM-YYYY").format("DD-MMM");

        raw_labels.push(date);
        raw_datasets_data.push(item.clicks.length);
      });

      if (raw_labels.length > 10) {
        raw_labels = raw_labels.slice(
          raw_labels.length - 10,
          raw_labels.length
        );
        raw_datasets_data = raw_datasets_data.slice(
          raw_datasets_data.length - 10,
          raw_datasets_data.length
        );
      }
      let dataSet = {
        labels: raw_labels,
        datasets: [
          {
            label: "Clicks Count",
            barThickness: 10,
            // backgroundColor: "#1890ff",
            borderColor: "#1890ff",
            borderWidth: 1,
            hoverBackgroundColor: "#82a8c6",
            hoverBorderColor: "#1890ff",
            data: raw_datasets_data,
          },
        ],
      };

      let options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
                precision: 0,
              },
            },
          ],
        },
      };
      return (
        <div style={{ border: "1px solid gray", marginTop: "10px" }}>
          <Line data={dataSet} options={options} />
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const { data, showGraphIndex, toggleDisplayGraph } = this.props;
    let avtarText =
      (this.props.data.linkType &&
        this.props.data.linkType.charAt(0).toUpperCase()) ||
      "";
    let clickCount = 0;
    if (this.props.data && this.props.data.count_click) {
      clickCount = this.props.data.count_click;
    }
    let linkIsActive = false;
    if (data.status === "active") {
      linkIsActive = true;
    }

    let url = this.props.data.url;
    let prefix = "http://";
    let prefix2 = "https://";
    if (
      url.substr(0, prefix.length) !== prefix &&
      url.substr(0, prefix2.length) !== prefix2
    ) {
      url = prefix + url;
    }

    let showGraph = false;
    if (showGraphIndex.indexOf(data._id) !== -1) {
      showGraph = true;
    }

    return (
      <Card
        style={{
          marginBottom: "20px",
          boxShadow: "rgb(17 17 17) 0px 15px 10px -15px",
        }}
        actions={[
          <div style={{ color: "black" }}>Clicks - {clickCount}</div>,
          <div>
            <Switch
              defaultChecked={linkIsActive}
              onChange={this._changeLinkStatus}
            />
          </div>,
          <div>
            <a href="#" onClick={this._onEditLink} style={{ color: "#1890ff" }}>
              <b>Edit</b>
            </a>
          </div>,
          <div>
            <Popconfirm
              title="Are you sure you want delete this link?"
              onConfirm={this._onDeleteLink}
              okText="Yes"
              cancelText="No"
            >
              <a href="#" style={{ color: "red" }}>
                <b>Delete</b>
              </a>
            </Popconfirm>
          </div>,
        ]}
      >
        <Meta
          avatar={
            <Avatar
              style={{
                color: "white",
                backgroundColor: "#1890FF",
                fontWeight: "bold",
                fontSize: "26px",
              }}
            >
              {avtarText}
            </Avatar>
          }
          title={this.props.data.linkType + " - " + this.props.data.title}
          description={
            <>
              <a href={url} target="_BLANK" style={{ fontSize: "18px" }}>
                {url}
              </a>
              <br />
              <Button
                type="link"
                onClick={() => {
                  toggleDisplayGraph(data._id);
                }}
                style={{
                  marginLeft: "-15px",
                  fontSize: "10px",
                  fontWeight: "bold",
                }}
              >
                Toggle Stats Graph
              </Button>

              {showGraph ? this._renderLinkClickGraph(data) : null}
              {/* {this._renderLinkClickGraph(data)} */}
            </>
          }
        />
      </Card>
    );
  }
}

export default ManageLink;
