import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  saveInstagramAccounts: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  myInfluencerPages: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  publicInfluencerPages: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  updateInfluencerInstagram: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  twitterAuth: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  twitterAuthVerify: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  updateInfluencerTwitter: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  newCampaign: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  myCampaigns: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  myCampaign: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  updateMyCampaign: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  activeCampaigns: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  applyCampaign: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
  saveYoutubeAccounts: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  updateInfluencerYoutube: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
  },
};

/* save instagram accounts */
const saveInstagramAccountsRequest = (state, action) =>
  update(state, {
    saveInstagramAccounts: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const saveInstagramAccountsSuccess = (state, action) =>
  update(state, {
    saveInstagramAccounts: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const saveInstagramAccountsError = (state, action) =>
  update(state, {
    saveInstagramAccounts: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* my influencers page */
const myInfluencerPagesRequest = (state, action) =>
  update(state, {
    myInfluencerPages: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const myInfluencerPagesSuccess = (state, action) =>
  update(state, {
    myInfluencerPages: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const myInfluencerPagesError = (state, action) =>
  update(state, {
    myInfluencerPages: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* public influencers page */
const publicInfluencerPagesRequest = (state, action) =>
  update(state, {
    publicInfluencerPages: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const publicInfluencerPagesSuccess = (state, action) =>
  update(state, {
    publicInfluencerPages: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const publicInfluencerPagesError = (state, action) =>
  update(state, {
    publicInfluencerPages: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* update influencer instagram */
const updateInfluencerInstagramRequest = (state, action) =>
  update(state, {
    updateInfluencerInstagram: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const updateInfluencerInstagramSuccess = (state, action) =>
  update(state, {
    updateInfluencerInstagram: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const updateInfluencerInstagramError = (state, action) =>
  update(state, {
    updateInfluencerInstagram: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* uptwitter auth */
const twitterAuthRequest = (state, action) =>
  update(state, {
    twitterAuth: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const twitterAuthSuccess = (state, action) =>
  update(state, {
    twitterAuth: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const twitterAuthError = (state, action) =>
  update(state, {
    twitterAuth: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* uptwitter auth verify*/
const twitterAuthVerifyRequest = (state, action) =>
  update(state, {
    twitterAuthVerify: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const twitterAuthVerifySuccess = (state, action) =>
  update(state, {
    twitterAuthVerify: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const twitterAuthVerifyError = (state, action) =>
  update(state, {
    twitterAuthVerify: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* update influencer twitter */
const updateInfluencerTwitterRequest = (state, action) =>
  update(state, {
    updateInfluencerTwitter: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const updateInfluencerTwitterSuccess = (state, action) =>
  update(state, {
    updateInfluencerTwitter: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const updateInfluencerTwitterError = (state, action) =>
  update(state, {
    updateInfluencerTwitter: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* new campaign */
const newCampaignRequest = (state, action) =>
  update(state, {
    newCampaign: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const newCampaignSuccess = (state, action) =>
  update(state, {
    newCampaign: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const newCampaignError = (state, action) =>
  update(state, {
    newCampaign: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* my campaigns */
const myCampaignsRequest = (state, action) =>
  update(state, {
    myCampaigns: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const myCampaignsSuccess = (state, action) =>
  update(state, {
    myCampaigns: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const myCampaignsError = (state, action) =>
  update(state, {
    myCampaigns: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* my campaign */
const myCampaignRequest = (state, action) =>
  update(state, {
    myCampaign: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const myCampaignSuccess = (state, action) =>
  update(state, {
    myCampaign: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const myCampaignError = (state, action) =>
  update(state, {
    myCampaign: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* update my campaign */
const updateMyCampaignRequest = (state, action) =>
  update(state, {
    updateMyCampaign: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const updateMyCampaignSuccess = (state, action) =>
  update(state, {
    updateMyCampaign: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const updateMyCampaignError = (state, action) =>
  update(state, {
    updateMyCampaign: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* active campaigns */
const activeCampaignsRequest = (state, action) =>
  update(state, {
    activeCampaigns: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const activeCampaignsSuccess = (state, action) =>
  update(state, {
    activeCampaigns: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const activeCampaignsError = (state, action) =>
  update(state, {
    activeCampaigns: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* apply campaign */
const applyCampaignRequest = (state, action) =>
  update(state, {
    applyCampaign: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const applyCampaignSuccess = (state, action) =>
  update(state, {
    applyCampaign: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const applyCampaignError = (state, action) =>
  update(state, {
    applyCampaign: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* save youtube accounts */
const saveYoutubeAccountsRequest = (state, action) =>
  update(state, {
    saveYoutubeAccounts: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const saveYoutubeAccountsSuccess = (state, action) =>
  update(state, {
    saveYoutubeAccounts: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const saveYoutubeAccountsError = (state, action) =>
  update(state, {
    saveYoutubeAccounts: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

/* update influencer youtube */
const updateInfluencerYoutubeRequest = (state, action) =>
  update(state, {
    updateInfluencerYoutube: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });
const updateInfluencerYoutubeSuccess = (state, action) =>
  update(state, {
    updateInfluencerYoutube: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
const updateInfluencerYoutubeError = (state, action) =>
  update(state, {
    updateInfluencerYoutube: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

export default handleActions(
  {
    [constants.SAVE_INSTAGRAM_ACCOUNTS_REQUEST]: saveInstagramAccountsRequest,
    [constants.SAVE_INSTAGRAM_ACCOUNTS_SUCCESS]: saveInstagramAccountsSuccess,
    [constants.SAVE_INSTAGRAM_ACCOUNTS_ERROR]: saveInstagramAccountsError,

    [constants.MY_INFLUENCER_PAGES_REQUEST]: myInfluencerPagesRequest,
    [constants.MY_INFLUENCER_PAGES_SUCCESS]: myInfluencerPagesSuccess,
    [constants.MY_INFLUENCER_PAGES_ERROR]: myInfluencerPagesError,

    [constants.PUBLIC_INFLUENCER_PAGES_REQUEST]: publicInfluencerPagesRequest,
    [constants.PUBLIC_INFLUENCER_PAGES_SUCCESS]: publicInfluencerPagesSuccess,
    [constants.PUBLIC_INFLUENCER_PAGES_ERROR]: publicInfluencerPagesError,

    [constants.UPDATE_INFLUENCER_INSTAGRAM_REQUEST]:
      updateInfluencerInstagramRequest,
    [constants.UPDATE_INFLUENCER_INSTAGRAM_SUCCESS]:
      updateInfluencerInstagramSuccess,
    [constants.UPDATE_INFLUENCER_INSTAGRAM_ERROR]:
      updateInfluencerInstagramError,

    [constants.TWITTER_AUTH_REQUEST]: twitterAuthRequest,
    [constants.TWITTER_AUTH_SUCCESS]: twitterAuthSuccess,
    [constants.TWITTER_AUTH_ERROR]: twitterAuthError,

    [constants.TWITTER_AUTH_VERIFY_REQUEST]: twitterAuthVerifyRequest,
    [constants.TWITTER_AUTH_VERIFY_SUCCESS]: twitterAuthVerifySuccess,
    [constants.TWITTER_AUTH_VERIFY_ERROR]: twitterAuthVerifyError,

    [constants.UPDATE_INFLUENCER_TWITTER_REQUEST]:
      updateInfluencerTwitterRequest,
    [constants.UPDATE_INFLUENCER_TWITTER_SUCCESS]:
      updateInfluencerTwitterSuccess,
    [constants.UPDATE_INFLUENCER_TWITTER_ERROR]: updateInfluencerTwitterError,

    [constants.NEW_CAMPAIGN_REQUEST]: newCampaignRequest,
    [constants.NEW_CAMPAIGN_SUCCESS]: newCampaignSuccess,
    [constants.NEW_CAMPAIGN_ERROR]: newCampaignError,

    [constants.MY_CAMPAIGNS_REQUEST]: myCampaignsRequest,
    [constants.MY_CAMPAIGNS_SUCCESS]: myCampaignsSuccess,
    [constants.MY_CAMPAIGNS_ERROR]: myCampaignsError,

    [constants.MY_CAMPAIGN_REQUEST]: myCampaignRequest,
    [constants.MY_CAMPAIGN_SUCCESS]: myCampaignSuccess,
    [constants.MY_CAMPAIGN_ERROR]: myCampaignError,

    [constants.UPDATE_MY_CAMPAIGN_REQUEST]: updateMyCampaignRequest,
    [constants.UPDATE_MY_CAMPAIGN_SUCCESS]: updateMyCampaignSuccess,
    [constants.UPDATE_MY_CAMPAIGN_ERROR]: updateMyCampaignError,

    [constants.ACTIVE_CAMPAIGNS_REQUEST]: activeCampaignsRequest,
    [constants.ACTIVE_CAMPAIGNS_SUCCESS]: activeCampaignsSuccess,
    [constants.ACITVE_CAMPAIGNS_ERROR]: activeCampaignsError,

    [constants.APPLY_CAMPAIGN_REQUEST]: applyCampaignRequest,
    [constants.APPLY_CAMPAIGN_SUCCESS]: applyCampaignSuccess,
    [constants.APPLY_CAMPAIGN_ERROR]: applyCampaignError,

    [constants.SAVE_YOUTUBE_ACCOUNTS_REQUEST]: saveYoutubeAccountsRequest,
    [constants.SAVE_YOUTUBE_ACCOUNTS_SUCCESS]: saveYoutubeAccountsSuccess,
    [constants.SAVE_YOUTUBE_ACCOUNTS_ERROR]: saveYoutubeAccountsError,

    [constants.UPDATE_INFLUENCER_YOUTUBE_REQUEST]:
      updateInfluencerYoutubeRequest,
    [constants.UPDATE_INFLUENCER_YOUTUBE_SUCCESS]:
      updateInfluencerYoutubeSuccess,
    [constants.UPDATE_INFLUENCER_YOUTUBE_ERROR]: updateInfluencerYoutubeError,
  },
  initialState
);
