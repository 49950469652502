import React from "react";
import { Link } from "react-router-dom";

import { Row, Col, Layout, Steps } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";
import Sample from "../images/sample-short-shout.png";
import InstagramSample from "../images/instagram-sample.png";
import RecentlyAddedUsers from "../components/RecentlyAddedUsers";

const { Content } = Layout;
const { Step } = Steps;

class HomeBrands extends React.Component {
  render() {
    return (
      <Layout id="page-home-brands">
        <HeaderMain />
        <Content className="site-layout">
          <Row>
            {/* <Col xl={10} md={10} xs={24}>
              <div id="info-shortshout" className="home-page-block-info">
                Get your public URL like -{" "}
                <a href="https://shortshout.in/shortshout" target="_BLANK">
                  https://shortshout.in/shortshout
                </a>
                <br />
                <br />
                <Steps direction="vertical" size="small">
                  <Step
                    status="finish"
                    title="Sign Up ( Yes It's Free!! )"
                    description="Sign up with your email or login with Google."
                  />
                  <Step
                    status="finish"
                    title="Add Links"
                    description="Add your social and professional links."
                  />
                  <Step
                    status="finish"
                    title="Share & Grow"
                    description={
                      <span>
                        You are done.
                        <br />
                        Your single link for multiple links is ready to share
                        with the web world.
                      </span>
                    }
                  />
                  <Step
                    status="finish"
                    title="Manage and View Links Reports"
                    description={
                      <span>View your page impressions and clicks report.</span>
                    }
                  />
                </Steps>
                <Link to="/home-shortshout">Learn More</Link>
              </div>
            </Col> */}
            <Col xl={24} md={24} xs={24}>
              <div id="how-it-works" className="home-page-block-info">
                <h1>Search Influencers to promote your content.</h1>
              </div>
              <div id="how-it-works" className="home-page-block-info">
                <div className="title">How it Works</div>
                <br />

                <Row>
                  <Col md={8} className="block">
                    <div className="heading">Step 1</div>
                    <div className="content">
                      <div className="info">
                        Join <Link to="/login">Shortshout</Link>
                        <br />
                        <br />
                        Create Campaign <br />
                        <br /> Explain about Campaign in details
                      </div>
                    </div>
                  </Col>
                  <Col md={8} className="block">
                    <div className="heading">Step 2</div>
                    <div className="content">
                      <div className="info">
                        Wait for suitable influencers to apply
                        <br />
                        Or
                        <br />
                        Search matched influencers
                      </div>
                    </div>
                  </Col>
                  <Col md={8} className="block">
                    <div className="heading">Step 3</div>
                    <div className="content">
                      <div className="info">
                        Look for influencers applied on your Campaign page.
                        <br />
                        <br />
                        <CheckOutlined className="pre-icon" />
                        Directly contact with user on his social profile.
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div id="how-it-works" className="home-page-block-info">
                <Link to="/list-influencers">View Influencers</Link>
              </div>
              {/* <img
                alt="shortshout.in public link view"
                style={{
                  // border: "4px solid #1890ff",
                  padding: "0px",
                  // borderRadius: "24px",
                  marginTop: "0px",
                  boxShadow: "rgb(17 17 17) 11px 25px 43px -17px",
                  height: "600px",
                }}
                src={Sample}
              /> */}
              {/* <br />
              <br />

              <img
                alt="shortshout.in instagram link in bio"
                src={InstagramSample}
                style={{
                  // border: "4px solid #1890ff",
                  padding: "0px",
                  // borderRadius: "24px",
                  marginTop: "46px",
                  boxShadow: "rgb(17 17 17) 11px 25px 43px -17px",
                  height: "650px",
                }} */}
              {/* /> */}
            </Col>
          </Row>

          {/* <RecentlyAddedUsers /> */}
        </Content>

        <FooterMain />
      </Layout>
    );
  }
}

export default HomeBrands;
