import React from "react";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "./styles/index.scss";

import Home from "./containers/Home";
import HomeShortshout from "./containers/HomeShortshout";
import HomeBrands from "./containers/HomeBrands";
import HomeInfluencers from "./containers/HomeInfluencers";

import ProgressBarWrapper from "./components/ProgressBarWrapper";

import AuthLoginPage from "./containers/AuthLoginPage";
import AuthRegisterPage from "./containers/AuthRegisterPage";
import PageResendVerificationLink from "./containers/PageResendVerificationLink";

import MyShortShout from "./containers/MyShortShout";
import ShortShoutMain from "./containers/ShortShoutMain";
import MasterAdmin from "./containers/MasterAdmin";
import Influencers from "./containers/Influencers";
import SocialProfileAuth from "./containers/SocialProfileAuth";

import PublicListInfluencers from "./containers/PublicListInfluencers";
import DashboardCampaigns from "./containers/DashboardCampaigns";
import ActiveCampaigns from "./containers/ActiveCampaigns";
import ManageCampaign from "./containers/ManageCampaign";

import hideForAuthUser from "./components/auth/hideForAuthUser";
import requireUserAuth from "./components/auth/requireUserAuth";
import requireMasterAdminAuth from "./components/auth/requireMasterAdminAuth";

import AuthTwitterCallBack from "./containers/AuthTwitterCallBack";
import AuthYoutubeCallBack from "./containers/AuthYoutubeCallBack";

import PrivacyPolicy from "./containers/PrivacyPolicy";

import RouteChangeTracker from "./RouteChangeTracker";

class App extends React.Component {
  constructor() {
    super();
    // Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
    if (process.env.NODE_ENV === "development") {
    } else {
      ReactGA.initialize("UA-39862096-10");
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/" component={HomeShortshout} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            {/* <Route exact path="/home-shortshout" component={HomeShortshout} /> */}
            <Route exact path="/home-brands" component={HomeBrands} />
            <Route exact path="/home-influencers" component={HomeInfluencers} />
            <Route
              exact
              path="/login"
              component={hideForAuthUser(AuthLoginPage)}
            />
            <Route
              exact
              path="/register"
              component={hideForAuthUser(AuthRegisterPage)}
            />
            <Route
              exact
              path="/resendVerificationLink"
              component={hideForAuthUser(PageResendVerificationLink)}
            />
            <Route
              exact
              path="/myshortshout"
              component={requireUserAuth(MyShortShout)}
            />
            <Route
              exact
              path="/influencers"
              component={requireUserAuth(Influencers)}
            />
            <Route
              exact
              path="/influencers/socialProfileAuth"
              component={requireUserAuth(SocialProfileAuth)}
            />
            <Route
              exact
              path="/influencers/twitterAuthCallback"
              component={requireUserAuth(AuthTwitterCallBack)}
            />
            <Route
              exact
              path="/influencers/youtubeAuthCallback"
              component={requireUserAuth(AuthYoutubeCallBack)}
            />
            <Route
              exact
              path="/influencers/dashboardCampaigns"
              component={requireUserAuth(DashboardCampaigns)}
            />
            <Route
              exact
              path="/influencers/manageCampaign/:campaignId"
              component={requireUserAuth(ManageCampaign)}
            />
            <Route
              path="/masteradmin"
              component={requireMasterAdminAuth(MasterAdmin)}
            />
            <Route path="/list-influencers" component={PublicListInfluencers} />
            <Route path="/activeCampaigns" component={ActiveCampaigns} />
            <Route path="/:shortShoutUsername" component={ShortShoutMain} />
          </Switch>
          <RouteChangeTracker />
        </Router>
        <ProgressBarWrapper />
      </>
    );
  }
}

export default App;
