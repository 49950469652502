import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Layout, Tabs, Collapse } from "antd";
import {
  DashboardOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

import HeaderMain from "../components/HeaderMain";
import PageTitle from "../components/PageTitle";
import FooterMain from "../components/FooterMain";

import MenuInfluencers from "../components/MenuInfluencers";

import FormCreateCampaign from "../components/influencers/FormCreateCampaign";
import MyCampaigns from "../components/influencers/MyCampaigns";

import * as actions from "../redux/actions";

const { Content } = Layout;
const { TabPane } = Tabs;

const { Panel } = Collapse;

function DashboardCampaigns(props) {
  // const [myCampaigns, setMyCampaigns] = useState([]);

  const { myCampaigns } = props;
  // const instagramPages =
  //   (myInfluencerPages.data && myInfluencerPages.data.instagramPages) || [];
  // const twitterPages =
  //   (myInfluencerPages.data && myInfluencerPages.data.twitterPages) || [];

  // const [hasInfluencersPages, setHasInfluencersPages] = useState(false);
  const [activeTab, setActiveTab] = useState("myCampaigns");
  useEffect(() => {
    props.myCampaignsRequest();
  }, []);

  // useEffect(() => {
  //   setMyCampaigns(myCampaigns);
  // }, [props.myCampaigns]);

  // const saveInstagramAccounts = (data) => {
  //   props.saveInstagramAccountsRequest(data);
  // };

  // const doUpdateInfluencerInstagram = (data) => {
  //   props.updateInfluencerInstagramRequest(data);
  // };

  // const doUpdateInfluencerTwitter = (data) => {
  //   props.updateInfluencerTwitterRequest(data);
  // };

  // const initiateTwitterAuth = () => {
  //   props.twitterAuthRequest();
  // };

  const createNewCampaign = (data) => {
    props.newCampaignRequest(data);
  };

  // console.log("------props");
  // console.log(props);
  // console.log(props);

  return (
    <Layout id="page-dashboard-campaigns">
      <HeaderMain />
      <Content className="site-layout">
        <MenuInfluencers />
        <PageTitle title="My Campaigns" />
        <br />
        <Collapse>
          <Panel header="New Campaign" key="1">
            <FormCreateCampaign createNewCampaign={createNewCampaign} />
          </Panel>
        </Collapse>

        <MyCampaigns myCampaigns={myCampaigns} />
      </Content>
      <FooterMain />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  login: state.auth.login,
  // instagramPages: state.influencers.myInfluencerPages.data.instagramPages || [],
  myCampaigns: state.influencers.myCampaigns.data || [],
});

const mapDispatchToProps = (dispatch) => ({
  saveInstagramAccountsRequest: (data) =>
    dispatch(actions.saveInstagramAccountsRequest(data)),
  myInfluencerPagesRequest: (data) =>
    dispatch(actions.myInfluencerPagesRequest(data)),
  updateInfluencerInstagramRequest: (data) =>
    dispatch(actions.updateInfluencerInstagramRequest(data)),
  twitterAuthRequest: (data) => dispatch(actions.twitterAuthRequest(data)),
  updateInfluencerTwitterRequest: (data) =>
    dispatch(actions.updateInfluencerTwitterRequest(data)),
  newCampaignRequest: (data) => dispatch(actions.newCampaignRequest(data)),
  myCampaignsRequest: (data) => dispatch(actions.myCampaignsRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardCampaigns)
);
